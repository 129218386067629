//import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';






Injectable()
export class BroadcastService {

  constructor() { }
  private subject = new Subject();


  subscribe(type: string, callback: (payload: any) => void): void {
    console.log(type);
    console.log(callback);
  }
  //subscribe(type: string, callback: (payload: any) => void): Subscription {
  //  return this._handler
  //    .filter(message => message.type === type)
  //    .map(message => message.payload)
  //    .subscribe(callback);
  //}



  sendMessage(message: string) {
    this.subject.next({ routeUrl: message });
  }

  clearMessages() {
    this.subject.next(null);
  }

  runMenuNavigationSave(): Observable<any> {
    return this.subject.asObservable();
  }
}
