import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateTimeFormatService } from '../../services/datetime-format.service';
import { Moment } from 'moment';
//import * as moment from 'moment';
import { DateValidationService } from '../../services/date-validation.service';
import { AppService } from '../../services/app.service';
import { Observable } from 'rxjs';
import { DataService } from '../../services/data.service';
import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker';

@Component({
  selector: 'elso-datetime-picker',
  templateUrl: './elso-datetimepicker.component.html',
  //styleUrls: ['./elso-datetimepicker.component.scss']
})

export class ELSODateTimePickerComponent {
  @Input() public model: FormControl;
  @Input() public id: string;
  @Input() public sectionName: string;
  @Input() public pickerType: string;
  @Input() public disablePickerReset: string;
  @Input() public otherDateComparsion: string;
  @Input() public dateBehind: FormControl;
  @Input() public dateFront: FormControl;
  @Input() public specialValidation: string;
 // @Input() public customOnChange: any;
  @Output() customOnChange: EventEmitter<any> = new EventEmitter();
  //@Input() onSuggestTest: (arg: FormControl, argg: FormControl) => void;
  isFired: boolean;

  constructor(public dateTimeService: DateTimeFormatService, public appService: AppService, private dataService: DataService) {

  }

  doSomething() {
    //console.log('child picker function called');
    this.customOnChange.emit();
  }


  //AllDateValidations: [];
  DateValueToValidate: any;
  DateFieldName: string;
  DateSectionName: string;
  DateOtherComparsion: string;
  //DateBehind: FormControl;
  //DateFront: FormControl;
  SpecialValidation: string;

  FormControl: FormControl;
  SoftErrors: any[];
  HardErrors: any[];


  checkValidations(data: any, fieldName: string, sectionName: string, formControl: FormControl, otherDateComparsion: string, behindDate: FormControl, frontDate: FormControl, specialValidation: string) {

    
    this.DateValueToValidate = data;
    //this.DateFieldName = fieldName;
    //this.DateSectionName = sectionName;
    this.FormControl = formControl;
    //this.DateOtherComparsion = otherDateComparsion;
    //this.DateBehind = behindDate;
    //this.DateFront = frontDate;

    this.validateDate();
  }

  validateDate = () => {

    this.SoftErrors = [];
    this.HardErrors = [];
    var allValidationsForField = [];
    allValidationsForField = this.appService.lodashFilterBy(this.appService.AllDateValidations, 'KeyId', this.id);//filter by field name
    allValidationsForField = this.appService.lodashFilterBy(allValidationsForField, 'Section', this.sectionName);//filter by section name
    //console.log(allValidationsForField);
    var dateToCompare = this.appService.toMomentDate(this.DateValueToValidate);//.format(this.dateTimeService.format);

    if (dateToCompare == null) {
      return null;
    }

    if (allValidationsForField.length > 0) {
      for (var i = 0; i <= allValidationsForField.length - 1; i++) {

        var compareAgainstDate = this.getCompareAgainstDateValue(allValidationsForField[i]);//.format(this.dateTimeService.format);
        if (compareAgainstDate != null) {
          switch (allValidationsForField[i].Operation) {
            case '>':

              //if (this.DateValueToValidate > this.appService.toMomentDate(new Date(compareAgainstDate))) {
              if (dateToCompare > compareAgainstDate) {

                if (allValidationsForField[i].TypeOfValidation == 'Hard') {
                  this.HardErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }

                if (allValidationsForField[i].TypeOfValidation == 'Soft') {

                  this.SoftErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }
              }

              break;
            case '>=':
              if (dateToCompare >= compareAgainstDate) {
                if (allValidationsForField[i].TypeOfValidation == 'Hard') {
                  this.HardErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }

                if (allValidationsForField[i].TypeOfValidation == 'Soft') {

                  this.SoftErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }
              }
              break;

            case '<':
              if (dateToCompare < compareAgainstDate) {
                if (allValidationsForField[i].TypeOfValidation == 'Hard') {
                  this.HardErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }

                if (allValidationsForField[i].TypeOfValidation == 'Soft') {
                  this.SoftErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }
              }
              break;
            case '<=':
              if (dateToCompare <= compareAgainstDate) {
                if (allValidationsForField[i].TypeOfValidation == 'Hard') {
                  this.HardErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }

                if (allValidationsForField[i].TypeOfValidation == 'Soft') {
                  this.SoftErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }
              }
              break;
            case '==':
              if (dateToCompare == compareAgainstDate) {
                if (allValidationsForField[i].TypeOfValidation == 'Hard') {
                  this.HardErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }

                if (allValidationsForField[i].TypeOfValidation == 'Soft') {
                  this.SoftErrors.push({ ErrorMessage: allValidationsForField[i].ErrorMessage, typeOfValidation: allValidationsForField[i].TypeOfValidation })
                }
              }
              break;
            default:
              return null;
          }
        }


          

        //} else {
        //}
      }

      //if (this.HardErrors.length > 0) {
      //  this.FormControl.setErrors({ hardError: true });
      //}
    }

    if (this.otherDateComparsion != null && this.otherDateComparsion != undefined) {
      var validationGroupArr = this.otherDateComparsion.split('|');

      for (i = 0; i <= validationGroupArr.length - 1; i++) {
        var validationObjectArr = validationGroupArr[i].split('-');
        //[0] table/object, [1] field name, [2] Hard/Soft, [3] comparison sign, [4] comparison part (min/hr/day/m/y), [5] number representing difference in (min/hr/day/m/y)

        var object = this.appService.getObjectToCompare(validationObjectArr[0]);
        if (object != undefined) {
          compareAgainstDate = object[validationObjectArr[1]];

          if (compareAgainstDate != undefined && compareAgainstDate != null) {
           // compareAgainstDate = this.appService.toMomentDate(compareAgainstDate);//convert to moment

            if (validationObjectArr[2] && validationObjectArr[3]) {

              if (validationObjectArr[4] && validationObjectArr[5]) {//comparing with (min/hr/day/m/y) and difference

              } else {//just comparing dates before/after/equal

                this.compareDates(validationObjectArr, dateToCompare, compareAgainstDate);

              }


            }
          }
        }

      }
    }

    var currentDate = this.appService.toMomentCurrentDate();

    if (dateToCompare > currentDate) {
      this.HardErrors.push({ ErrorMessage: 'This date cant be in future', typeOfValidation: 'Hard' })
    }

    if (this.HardErrors.length > 0) {
      this.FormControl.setErrors({ hardError: true });
    }

  }

  getMinMaxHardSoftValues(): any {
    return this.appService.AllDateValidations;
  }

  //changeEvent(event: MatDatepickerInputEvent<Date>) {
  changeEvent(data) {
    this.customOnChange.emit();
  //  this.onSuggestTest(this.model, this.dateBehind);
  //  this.onSuggestTest(this.model, this.dateFront);
    this.checkValidations(data.value, this.id, this.sectionName, this.model, this.otherDateComparsion, this.dateBehind, this.dateFront, this.specialValidation);
  }

  ngAfterViewInit() {
    this.customOnChange.emit();//this line is a must here, in order to sucessfuly validate all run details
    this.checkValidations(this.model.value, this.id, this.sectionName, this.model, this.otherDateComparsion, this.dateBehind, this.dateFront, this.specialValidation);

    //need to figure out how to call this method when rundetail is deleted
    
    //setTimeout(() => {
    //  this.checkValidations(this.model.value, this.id, this.setionName, this.model);
    //}, 500);//timeout with 500 at least must be set here in order for this to work
  }
  ngOnInit() {
    this.checkValidations(this.model.value, this.id, this.sectionName, this.model, this.otherDateComparsion, this.dateBehind, this.dateFront, this.specialValidation); //this is not needed when we have it in AfterViewInit, need to test this. AfterExpression error is fired if this line is not present
  }

  compareDatesWithTimeDifference(object: any, dateToCompare: any, compareAgainstDate: any) {
    var compareAgainstDateMoment = this.appService.toMomentDate(compareAgainstDate);
    var errorExist = false;
    switch (object[3]) {
      case '>':
        if (dateToCompare > compareAgainstDateMoment) {
          errorExist = true;
        }

        break;
      case '>=':
        if (dateToCompare >= compareAgainstDateMoment) {
          errorExist = true;
        }
        break;

      case '<':
        if (dateToCompare < compareAgainstDateMoment) {
          errorExist = true;
        }
        break;
      case '<=':
        if (dateToCompare <= compareAgainstDateMoment) {
          errorExist = true;
        }
        break;
      case '==':
        if (dateToCompare == compareAgainstDateMoment) {
          errorExist = true;
        }
        break;

    }
    if (errorExist == true) {

      var formatedDate = compareAgainstDateMoment.locale(this.dateTimeService.locale).format(this.dateTimeService.format);
      if (object[2] == 'Hard') {
        this.HardErrors.push({ ErrorMessage: this.appService.getDateErrorMessage(object[1], object[3]) + ' ' + formatedDate, typeOfValidation: 'Hard' })
      } else if (object[2] == 'Soft') {
        this.SoftErrors.push({ ErrorMessage: this.appService.getDateErrorMessage(object[1], object[3]) + ' ' + formatedDate, typeOfValidation: 'Soft' });
      }

    }

  }

  compareDates(object: any, dateToCompare: any, compareAgainstDate: any) {
    var compareAgainstDateMoment = this.appService.toMomentDate(compareAgainstDate);
    var errorExist = false;
    switch (object[3]) {
      case '>':
        if (dateToCompare > compareAgainstDateMoment) {
          errorExist = true;
        }
        break;
      case '>=':
        if (dateToCompare >= compareAgainstDateMoment) {
          errorExist = true;
        }
        break;
      case '<':
        if (dateToCompare < compareAgainstDateMoment) {
          errorExist = true;
        }
        break;
      case '<=':
        if (dateToCompare <= compareAgainstDateMoment) {
          errorExist = true;
        }
        break;
      case '==':
        if (dateToCompare == compareAgainstDateMoment) {
          errorExist = true;
        }
        break;
    }

    if (errorExist == true) {

      var formatedDate = compareAgainstDateMoment.locale(this.dateTimeService.locale).format(this.dateTimeService.format);

      if (object[2] == 'Hard') {
        this.HardErrors.push({ ErrorMessage: this.appService.getDateErrorMessage(object[1], object[3]) + ' ' + formatedDate, typeOfValidation: 'Hard' })
      } else if (object[2] == 'Soft'){
        this.SoftErrors.push({ ErrorMessage: this.appService.getDateErrorMessage(object[1], object[3]) + ' ' + formatedDate, typeOfValidation: 'Soft' });
      }

    }


  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.appService.toMomentDate(this.model.value);
    if (ctrlValue != null) {//07-27-2023
      ctrlValue.year(normalizedYear.year);
      this.model.setValue(ctrlValue);
    }
  }

  chosenMonthHandler(normalizedMonth: Moment) {//commented out because it resets date picker when month is chosen and day is not yet selected
    //const ctrlValue = this.appService.toMomentDate(this.model.value);
    //if (ctrlValue != null) {//07-27-2023
    //  ctrlValue.month(normalizedMonth.month);
    //  this.model.setValue(ctrlValue);
    //}
  }

  chosenDayHandler(normalizedDay: Moment, datepicker: OwlDateTimeComponent<Moment>) {
    const ctrlValue = this.appService.toMomentDate(this.model.value);
    if (ctrlValue != null) {//07-27-2023
      ctrlValue.day(normalizedDay.day);
      this.model.setValue(ctrlValue);
    }
    datepicker.close();
  }

  resetDatePickerValue() {
    //this.dateTimeService.testCallFromDatePicker();
    this.model.setValue(null);
    this.customOnChange.emit();

    //revalidate with null value
    this.checkValidations(null, this.id, this.sectionName, this.model, this.otherDateComparsion, this.dateBehind, this.dateFront, this.specialValidation);
  }

  getCompareAgainstDateValue(compareAgainstObject: any) {
    var dateString = null;
    switch (compareAgainstObject.ParentObject) {
      case 'Run':
        dateString = this.appService.Run[compareAgainstObject.CompareAgainstField];
        break;
      case 'ECPR2020Addendum':
        dateString = this.appService.Run.ECPR2020Addenda[0][compareAgainstObject.CompareAgainstField];
        break;
      case 'Cardiac2022Addendum':
        dateString = this.appService.Run.Cardiac2022Addenda[0][compareAgainstObject.CompareAgainstField];
        break;
      case 'Patient':
        dateString = this.appService.Patient[compareAgainstObject.CompareAgainstField];
        break;
    }

    if (dateString == null) {
      return null;
    } else {
      var momentDate = this.appService.toMomentDate(dateString);
      if (compareAgainstObject.IncreaseDecreaseDateValue != null && compareAgainstObject.IncreaseDecreaseMeasureUnit != null) {
        return momentDate.add(compareAgainstObject.IncreaseDecreaseDateValue, compareAgainstObject.IncreaseDecreaseMeasureUnit);
      } else {
        return momentDate;
      }
    }
   
  }
}
