<div [hidden]="appService.isLoading | async">
  <span>ECLS Runs:</span>
  <br />
  <br />
  <label>Search: &nbsp;</label>
  <input type="text" name="query" [(ngModel)]="query" placeholder="Search by uniqueid" />
  <br />
  <br />
  <div class="elsoGridFilters">
    <div style="display:inline-block">
      <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
        <mat-select hideSingleSelectionIndicator name="elsoGridSortByProperty" id="elsoGridSortByProperty" placeholder="-Sort by-" [(ngModel)]="elsoGridSortBy">
          <mat-option>-Sort by-</mat-option>
          <mat-option *ngFor="let sortObj of
                    [
                      { PropertyName: 'UniqueId', PropertyDisplayName: 'UniqueId'},
                      { PropertyName: 'RunNo', PropertyDisplayName: 'Run no'},
                      { PropertyName: 'TimeOn', PropertyDisplayName: 'Time on'},
                      { PropertyName: 'Birthdate', PropertyDisplayName: 'Birthdate'},
                      { PropertyName: 'submittedDate', PropertyDisplayName: 'Submitted'}
                    ]"
                      [value]="sortObj.PropertyName">
            {{sortObj.PropertyDisplayName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    &nbsp;&nbsp;&nbsp;
    <div style="display:inline-block">
      <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
        <mat-select hideSingleSelectionIndicator name="elsoGridOrderByProperty" id="elsoGridOrderByProperty" placeholder="-Asc/Desc-" [(ngModel)]="elsoGridOrderBy">
          <mat-option>-Asc/Desc-</mat-option>
          <mat-option *ngFor="let orderByObj of ['asc', 'desc']" [value]="orderByObj">
            {{orderByObj}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="elso-grid">
    <div class="elso-grid-row" *ngFor="let run of (allRuns | elsoGridFilter:query:elsoGridSortBy:elsoGridOrderBy |
                         slice: paginatorService.lowValue : paginatorService.highValue)">
      <div>
        <!--<a [routerLink]="['/run/runinfo/' + run.RunId]">-->
        <a (click)="loadRun(run.RunId)">
          <mat-icon class="mat-icon" matTooltip="Run" matTooltipPosition="above" color="primary">assignment</mat-icon>
        </a>
        <a (click)="loadPatient(run.PatientId)">
          <mat-icon class="mat-icon" matTooltip="Patient" matTooltipPosition="above" color="primary">assignment_ind</mat-icon>
        </a>
      </div>
      <div>
        <span class="elso-grid-label">Unique id: </span>
        <span>{{run.UniqueId}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Run no: </span>
        <span>{{run.RunNo}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Birthdate: </span>
        <span>{{run.Birthdate | dateFormat}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Age: </span>
        <span>{{appService.getFormatedStringFromDays(run.Age)}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Time on: </span>
        <span>{{run.TimeOn | dateFormat}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Time off: </span>
        <span>{{run.TimeOff | dateFormat}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Submitted: </span>
        <span>{{run.submittedDate != null ? 'Yes' : 'No'}}</span>
      </div>
    </div>
  </div>

  <br />
  <br />
  <mat-paginator *ngIf="allRuns" [length]="allRuns.length" pageSize=5
                 (page)="paginatorService.getPaginatorData($event)" [pageSizeOptions]="[5, 20, 30]"
                 showFirstLastButtons="false">
  </mat-paginator>
</div>
