//lodash
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
//import * as moment from 'moment';
import moment from 'moment';

import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { UUID } from 'angular2-uuid';
//import { BooleanFn } from '../components/run/addenda/covid/covidAddendaEdit.component';

export interface BooleanFn {
  (): boolean;
}
Injectable()
export class AppService {
  conditionalValidator(
    predicate: BooleanFn,
    validator: ValidatorFn
  ): ValidatorFn {
    return formControl => {
      //console.log(formControl);
      //console.log(predicate);
      if (!formControl.parent) {
        return null;
      }
      let error = null;
      if (predicate()) {
        error = validator(formControl);
      }
      return error;
    };
  }
  constructor() {
    if (this.UserProfile == undefined) {
      this.setUserProfile();
    }
  }
  public isLoading = new BehaviorSubject(false);
  public registryVersion = '5.4.8';
  
  InRunEditMode: string = '';

  Run: any;
  Patient: any;
  AllDateValidations: any[];
  MinMaxHardSoftValues: [];
  UserProfile: any;
  Center: any;
  RunEnumsList: any;
  allWizardOptions: [];

  enumMembraneLungs: any;
  enumMembraneLungsManufacturers: any;
  enumBloodPumps: any;
  enumBloodPumpsManufacturers: any;
  enumHemofilters: any;
  enumHemofiltersManufacturers: any;
  enumTemperatureRegulations: any;
  enumTemperatureRegulationsManufacturers: any;
  enumCannulas: any;
  enumCannulasManufacturers: any;
  enumCannulationSites: any;
  enumCannulationReplacementReasons: any;
  enumComplicationCodes: any;

  membraneLungsCenterSpecific: any;
  bloodPumpsCenterSpecific: any;
  hemofiltersCenterSpecific: any;
  temperatureRegulationsCenterSpecific: any;
  cannulasCenterSpecific: any;

  MLReplacementCodes: any;
  BPReplacementCodes: any;
  CReplacementCodes: any;

  //ECPR 2020
  enumECPRAntecedentEventCodes: any;
  enumCMConditionCodes: any;
  enumECPROOHCACodes: any;
  enumECPR2020CompressionMethodsUsed: any;
  enumECPR2020MedicationCodes: any;
  enumECPR2020OiDuringCPAcodes: any;
  //checkMode(routeLink: string): string {
  //  if (this.InEditMode) {
  //    routeLink = routeLink + '/edit';
  //  }
  //  return routeLink + '/' + this.Run.RunId;
  //}
  setUserProfile(): void {
   // if (localStorage.getItem('cultureSettings') != null && localStorage.getItem('cultureOptionSelected') != null && localStorage.getItem('unitSystem') != null) {

      this.UserProfile = {
        UnitSystem: localStorage.getItem('unitSystem'),
        CultureOptionSelected: localStorage.getItem('cultureOptionSelected'),
        DateTimeFormat: localStorage.getItem('cultureSettings'),
        UserRoles: this.getUserRoles(),
        CanEditRunPatient: this.roleMatch(this.editPatientRunRoles),
        CenterName: localStorage.getItem('centerName'),
        CenterId: localStorage.getItem('centerId'),
        UserName: localStorage.getItem('userName'),  
      }
    //console.log(this.UserProfile.CanEditRunPatient);
      //console.log(this.UserProfile);
    //} else {//need to logout user here

    //}
  }

  setAppRun(UpdatedRun: any): void {
    this.Run = cloneDeep(UpdatedRun);
  }

  setAppRunAndValidation(RunWithValidation: any): void {
    this.Run = cloneDeep(RunWithValidation.Run);
    this.AllDateValidations = RunWithValidation.DateValidationList;
    this.MinMaxHardSoftValues = RunWithValidation.MinMaxHardSoftValidationList;
  }

  //Codes/options from database
  PreECLSSupportCodes: any[];
  userCenters = [];
  public form!: FormGroup;
  fb: FormBuilder;

  setRunForm(run: any): FormGroup {
    this.fb = new FormBuilder;
    this.form = this.fb.group({});
    this.form = this.fb.group(run);
    //console.log(run);
    this.form.controls['Supports'] = this.fb.array(run.Supports);
    this.form.controls['BloodGases'] = this.fb.array(run.BloodGases);
    this.form.controls['VentSettings'] = this.fb.array(run.VentSettings);
    this.form.controls['Hemodynamics'] = this.fb.array(run.Hemodynamics);
    this.form.controls['Equipment'] = this.fb.array(run.EquipmentHistories);
    this.form.controls['EquipmentHistories'] = this.fb.array(run.EquipmentHistories);
    this.form.controls['RunDetails'] = this.fb.array(run.RunDetails);
    this.form.controls['Cannulations'] = this.fb.array(run.Cannulations);
    this.form.controls['Complications'] = this.fb.array(run.Complications);
    this.form.controls['Infections'] = this.fb.array(run.Infections);
    this.form.controls['Diagnosis1s'] = this.fb.array(run.Diagnosis1s);
    this.form.controls['ICD10WizardSavedCodes'] = this.fb.array(run.ICD10WizardSavedCodes);
    this.form.controls['CPTProcedures'] = this.fb.array(run.CPTProcedures);
    this.form.controls['SeverityScores'] = this.fb.array(run.SeverityScores);
    this.form.controls['CovidAddenda'] = this.fb.array(run.CovidAddenda);
    this.form.controls['ECPR2020Addenda'] = this.fb.array(run.ECPR2020Addenda);
    this.form.controls['ECPRAddenda'] = this.fb.array(run.ECPRAddenda);
    this.form.controls['CardiacAddenda'] = this.fb.array(run.CardiacAddenda);
    this.form.controls['Cardiac2022Addenda'] = this.fb.array(run.Cardiac2022Addenda);
    this.form.controls['TraumaAddenda'] = this.fb.array(run.TraumaAddenda);

    this.form.controls['AscendAddenda'] = this.fb.array(run.AscendAddenda);
    //console.log(this.form.value);
    return this.form;
  }

  setECPRAddendumForm(ecprAddendum: any): FormGroup {
    this.fb = new FormBuilder;
    this.form = this.fb.group({});
    this.form = this.fb.group(ecprAddendum);
    this.form.controls['ECPR2020AntecedentEvents'] = this.fb.array(ecprAddendum.ECPR2020AntecedentEvents);
 
    return this.form;
  }

  centerQuery = null;
  filteredCenters = [];
  //Enums
  ///////////RunInfo
  enumForceViewModeSections = [
    { name: 'procedures' },
    { name: 'cannulations' },
    { name: 'infections' },
    { name: 'diagnoses' },
    { name: 'complications' },
    { name: 'addeda' },
    { name: 'main' }//main addenda section
  ];
  enumSupportTypes = [
    { name: 'Pulmonary', value: 1 },
    { name: 'Cardiac', value: 2 },
    { name: 'ECPR', value: 3 }
  ];
  enumIntubation = [
    { name: 'Yes date/time known', value: 1 },
    { name: 'Pre-Existing invasive ventilation', value: 2 },
    { name: 'Yes date/time unknown', value: 3 },
    { name: 'Yes date/time estimate', value: 4 },
    { name: 'No', value: 0 }
  ];
  enumInvasiveVentilation = [
    { name: 'New date/time known', value: 1 },
    { name: 'Pre-existing invasive ventilation', value: 2 },
    { name: 'Yes date/time unknown', value: 3 },
    { name: 'Yes date/time estimate', value: 4 },
    { name: 'No', value: 0 }
  ];
  enumCoVID = [
    { name: 'COVID19 confirmed by testing', value: 1 },
    { name: 'COVID19 suspected but NO testing', value: 2 },
    { name: 'No clinical suspicion of COVID19 (and no testing)', value: 3 },
    { name: 'COVID19 confirmed negative', value: 4 }
  ];
  enumMisC = [
    { name: 'Clinically Suspected/Confirmed', value: 1 },
    { name: 'Not Clinically Suspected', value: 2 }
  ];
  //////////////////////////////////////////
  ///////////Pre/ECLS Assessment
  enumVentTypes = [
    { name: 'Other', value: 0 },
    { name: 'Conventional', value: 1 },
    { name: 'HFO', value: 2 },
    { name: 'OtherHFV', value: 3 },
    { name: 'No Ventilator', value: 4 }
  ];
  enumPreSupportICU = [
    { name: 'Neonatal ICU', value: 0 },
    { name: 'Pediatric ICU', value: 1 },
    { name: 'Pediatric cardiac ICU', value: 2 },
    { name: 'Adult Medicine ICU', value: 3 },
    { name: 'Adult Surgical ICU', value: 4 },
    { name: 'Adult Cardiac ICU', value: 5 },
    { name: 'Adult Cardiovascular ICU', value: 6 },
    { name: 'Mixed ICU', value: 7 },
    { name: 'ECLS ICU', value: 8 },
    { name: 'Emergency Department', value: 9 },
    { name: 'Operating Room/Cath Lab', value: 10 }, 
    { name: 'Burn ICU', value: 11 } 
  ];
  enumMobilizationLevels = [
    { name: '0 Nothing (lying in bed)', value: 0, title: 'Passively rolled or passively exercised by staff, but not actively moving' },
    { name: '1 Sitting in bed, exercises in bed', value: 1, title: 'Any activity in bed, including rolling, bridging, active exercises, cycle ergometry and active assisted exercises; not moving out of bed or over the edge of the bed' },
    { name: '2 Passively moved to chair (no standing) ', value: 2, title: 'Hoist, passive lift or slide transfer to the chair, with no standing or sitting on the edge of the bed' },
    { name: '3 Sitting over edge of bed', value: 3, title: 'May be assisted by staff, but involves actively sitting over the side of the bed with some trunk control' },
    { name: '4 Standing ', value: 4, title: 'Weight bearing through the feet in the standing position, with or without assistance. This may include use of a standing lifter device or tilt table' },
    { name: '5 Transferring bed to chair', value: 5, title: ' Able to step or shuffle through standing to the chair. This involves actively transferring weight from one leg to another to move to the chair. If the patient has been stood with the assistance of a medical device, they must step to the chair (not included if the patient is wheeled in a standing lifter device)' },
    { name: '6 Marching on spot (at bedside)', value: 6, title: 'Able to walk on the spot by lifting alternate feet (must be able to step at least 4 times, twice on each foot), with or without assistance' },
    { name: '7 Walking with assistance of 2 or more people', value: 7, title: 'Walking away from the bed/chair by at least 5 m (5 yards) assisted by 2 or more people' },
    { name: '8 Walking with assistance of 1 person', value: 8, title: 'Walking away from the bed/chair by at least 5 m (5 yards) assisted by 1 person' },
    { name: '9 Walking independently with a gait aid', value: 9, title: 'Walking away from the bed/chair by at least 5 m (5 yards) with a gait aid, but no assistance from another person. In a wheelchair bound person, this activity level includes wheeling the chair independently 5 m (5 years) away from the bed/chair' },
    { name: '10 Walking independently without a gait aid', value: 10, title: 'Walking away from the bed/chair by at least 5 m (5 yards) without a gait aid or assistance from another person' }
  ];
  enumYesNo = [
    { name: 'Yes', value: 1 },
    { name: 'No', value: 0 }
  ];
  //////////////////////////////////////////
  ///////////Modes/RunDetails
  enumRunModes = [
    { name: 'VA', value: 1, disabled: false },
    { name: 'VV', value: 2, disabled: false },
    { name: 'VVA', value: 3, disabled: false },
    { name: 'AVCO2R', value: 4, disabled: false },
    { name: 'VVECCO2R', value: 5, disabled: false },
    { name: 'VP', value: 6, disabled: false },
    { name: 'Unknown', value: 0, disabled: true },
    { name: 'Other', value: 9, disabled: false }
  ];
  enumYesNoBoolean = [
    { name: 'Yes', value: true },
    { name: 'No', value: false }
  ];

  enumScanDeviceTypes = [
    { name: 'Membrane Lung', value: 1 },
    { name: 'Blood Pump', value: 2 },
    { name: 'Heat Exchanger', value: 3 },
    { name: 'Hemofilter', value: 4 },
    { name: 'Temperature Regulation', value: 5 },
    { name: 'Cannula', value: 6 },
  ];


  ///////////PatientInfo
  enumSex = [
    { name: 'Unknown', value: 0 },
    { name: 'Male', value: 1 },
    { name: 'Female', value: 2 }
  ];
  enumCDHYesNoUnknown = [
    { name: 'Yes', value: 1 },
    { name: 'No', value: 0 },
    { name: 'Unknown', value: -1 }
  ];
  enumCannulaLigatedRepaired = [
    { name: 'Repaired', value: 1 },
    { name: 'Ligated', value: 0 },
    { name: 'None', value: -1 }
  ];
  enumCDHSide = [
    { name: 'Unknown', value: 0 },
    { name: 'Left', value: 1 },
    { name: 'Right', value: 2 },
    { name: 'Bilateral', value: 3 },
  ];
  enumCDHRepair = [
    { name: 'None', value: 0 },
    { name: 'PreECLS', value: 1 },
    { name: 'OnECLS', value: 2 },
    { name: 'PostECLS', value: 3 },
  ];
  enumCDHRepairYesNo = [
    { name: 'No', value: 0 },
    { name: 'Yes', value: 1 },
  ];

  enumRace = [
    { name: 'Unknown', value: 0 },
    { name: 'Asian', value: 1 },
    { name: 'Black', value: 2 },
    { name: 'Hispanic', value: 3 },
    { name: 'White', value: 4 },
    { name: 'Middle Eastern or North African', value: 5 },
    { name: 'Native American', value: 6 },
    { name: 'Native Pacific Islander', value: 7 },
    { name: 'Other', value: 9 }
  ];

  enumDelivery = [
    { name: 'Unknown', value: 0 },
    { name: 'Vaginal', value: 1 },
    { name: 'Emergency C-Section', value: 2 },
    { name: 'Elective C-Section', value: 3 }
  ];
  //////////////////////////////////////////
  ///////////PreECLSSupport
  enumPatientTransported = [
    { name: 'Transported on ECMO', value: 1 },
    { name: 'Transported not on ECMO', value: 0 },
    { name: 'Not transported', value: 2 },
    { name: 'Unknown', value: -1 }
  ];
  //////////////////////////////////////////
  //Equipment history devices
  enumConcurrentDeviceNames = [
    { name: 'Initial', value: 0 },
    { name: 'Second Concurrent', value: 1 },
    { name: 'Third Concurrent', value: 2 },
    { name: 'Fourth Concurrent', value: 3 },
    { name: 'Fifth Concurrent', value: 4 },
    { name: 'Sixth Concurrent', value: 5 },
    { name: 'Seventh Concurrent', value: 6 },
    { name: 'Eqighth Concurrent', value: 7 },
    { name: 'Ninth Concurrent', value: 8 },
    { name: 'Tenth Concurrent', value: 9 },
    { name: 'Eleventh Concurrent', value: 10 },
    { name: 'Twelveth Concurrent', value: 11 }
  ];
  //////////////////////////////////////////
  enumProceduresEstimated = [
    { name: 'Yes', value: true },
    { name: 'No', value: false }
  ];
  /////////////////////////////////////////
  enumYesNoUnknown = [
    { name: 'Yes', value: 1 },
    { name: 'No', value: 0 },
    { name: 'Unknown', value: -1 }
  ];

  enumTRAUMAYesNo = [
    { name: 'Yes', value: true },
    { name: 'No', value: false }
  ];
  
  enumGraphs = [
    { name: 'Your Overall Runs', value: 0 },
    { name: 'Volume – Children (Pediatric & Neonatal)', value: 1 },
    { name: 'Volume – Adult', value: 2 }
  ];

  enumCultureOptions = [
    { name: 'Default browser culture', value: 0 },
    { name: '24h time format', value: 1 },
    { name: '12h time format', value: 2 }
  ];

  //Settings
  enumFormatOptions = [
    { name: 'Default culture 12 hour format', value: 1 },
    { name: 'Default culture 24 hour format', value: 2 },
    { name: 'en-US culture 12 hour format', value: 3 },
    { name: 'en-US culture 24 hour format', value: 4 },
  ];
  enumUnitSystem = [
    { name: 'US', value: 1 },
    { name: 'SI', value: 0 }
  ];
  //Modes

  /////////////////////////////////////////
  //Cannulations
  enumCannulationPurposes = [
    { name: 'Drainage', value: 1 },
    { name: 'Return', value: 2 },
    { name: 'Both drainage and return', value: 3 },
    { name: 'Distal perfusion catheter(DPC)', value: 4 }
  ];
  /////////////////////////////////////////
  enumInitialDeviceOrderName = [
    { name: 'Initial', value: 0 },
    { name: 'Second concurrent', value: 1 },
    { name: 'Third concurrent', value: 2 },
    { name: 'Fourth concurrent', value: 3 },
    { name: 'Fifth concurrent', value: 4 },
    { name: 'Sixth concurrent', value: 5 },
    { name: 'Seventh concurrent', value: 6 },
    { name: 'Eqighth concurrent', value: 7 },
    { name: 'Ninth concurrent', value: 8 },
    { name: 'Tenth concurrent', value: 9 },
    { name: 'Eleventh concurrent', value: 10 },
    { name: 'Twelveth concurrent', value: 11 }
  ];
  //////ECPR Addendum 2020//////////////////
  enumDebriefPostECPRTF = [
    { name: 'within 24 hours', value: 1 },
    { name: 'within 1 month', value: 2 },
    { name: 'within 3 months', value: 3 }
  ];
  //enumPrecipitatingEvents = [
  //  { name: 'within 24 hours', value: 1 },
  //  { name: 'within 1 month', value: 2 },
  //  { name: 'within 3 months', value: 3 }
  //]

 
  IsUs() {
    var isUs = false;
    if (this.UserProfile.UnitSystem == 1) { // US
      isUs = true;
    }
    else if (this.UserProfile.UnitSystem == 0) { // SI
      isUs = false;
    }
    else if (this.UserProfile.UnitSystem == 2) { // US
      isUs = true;
    }

    return isUs
  };
  ExcludedConditions: any[];
  EducationLevels: any[];
  HCInsuranceStatuses: any[];
  enumPMHx: any[];
  /////////////////////
  //moment
  toMomentDate(val): any {
    if (val == null) {
      return null;
    }
    return moment(val).utc(true);
  }
  toMomentCurrentDate(): any {
    return moment().utc(true);
  }
  ///

  //lodash
  lodashRemoveBy(array, propertyName, propertyValue) : any {
    return _.remove(array, [propertyName, propertyValue]);
  };
  lodashWithout(array, value): any {
    return _.without(array, value);
  };
  lodashFindBy(array, propertyName, propertyValue) {
    return _.find(array, [propertyName, propertyValue]);
  };
  lodashFindByMultiple(array, propertyNames, propertyValues) {
    for (var i = 0; i <= propertyNames.length - 1; i++) {
      array = _.filter(array, [propertyNames[i], propertyValues[i]]);
    }
    return array[0];
  };
  lodashFilterBy(array, propertyName, propertyValue) {
    return _.filter(array, [propertyName, propertyValue]);
  };
  lodashFilterByMultiple(array, propertyNames, propertyValues) {
    for (var i = 0; i <= propertyNames.length - 1; i++) {
      array = _.filter(array, [propertyNames[i], propertyValues[i]]);
    }
    return array;
  };
  lodashSortBy(array, propertyName) {
    return _.sortBy(array, [propertyName]);
  };
  lodashMapBy(array, propertyName) {
    return _.map(array, propertyName);
  };
  lodashUniqeInitialDevices(array) {
    return _.uniq(_.map(array, 'InitialDeviceId'));
  };
  lodashUniq(array) {
    return _.uniq(array);
  };
  lodashUniqByProperty(array, property) {
    return _.uniq(_.map(array, property));
  };
  lodashDeepClone(obj) {
    return _.cloneDeep(obj);
  };
  lodashOrderBy(array, propertyArray, ascDescArray): any {
    return _.orderBy(array, propertyArray, ascDescArray);
  };
  lodashMaxNumberValue(array): number {
    return _.max(array);
  }
  ///

  //measure units/conversion
  getMeasureUnit(conversionType): string {

    //get unit here from global variable or local storage
    var isUS = false;
    if (this.UserProfile.UnitSystem == 1) {
      isUS = true;
    }

    var ret = '';
    var spl = conversionType.split('-'); //[0] SI, [1] US
    if (isUS) {
      ret = spl[1];
    } else {
      ret = spl[0];
    }
    return ret;
  }


  getObjectToCompare(objectName: string): any {
    switch (objectName) {
      case 'p':
        return this.Patient;
      case 'r':
        return this.Run;        
    }

    return undefined;
  }


  getDateErrorMessage(objectName: string, comparisonSign: string): string {
    var comparisonWord = '';
    if (comparisonSign == '>') {
      comparisonWord = 'after';
    } else if (comparisonSign == '<') {
      comparisonWord = 'before';
    }

    switch (objectName) {
      case 'Birthdate':
        return 'This date cant be before patient Birthdate';
      case 'TimeOn':
        return 'This date cant be ' + comparisonWord + ' run TimeOn';
      case 'DeathDate':
        return 'This date cant be ' + comparisonWord + ' DeathDate';
      case 'TimeOff':
        return 'This date cant be after run TimeOff';
    }
  }

  editPatientRunRoles = ['ECLS Center Administrator', 'ECLS Center Data Entry', 'ECLS Center Data Manager', 'Host User', 'Host User - Registry Only']

  roleMatch(allowedRoles): boolean {
    var isMatch = false;
    if (localStorage.getItem('token') != undefined) {
      var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      var userRoles = payLoad.role;
      //console.log(payLoad);
      //console.log(localStorage.getItem('token'));

      if (userRoles != undefined) {
        for (var i = 0; i <= userRoles.length - 1; i++) {
          if (allowedRoles.indexOf(userRoles[i]) > -1) {
            isMatch = true;
          }
        }
      }
      
    }
    

    //allowedRoles.forEach(element => {
    //  if (userRole == element) {
    //    isMatch = true;
    //    return false;
    //  }
    //})
    return isMatch;
  }

  isUserInRole(roleName): boolean {
    if (this.UserProfile) {
      //console.log(this.UserProfile);
      var userRoles = this.UserProfile.UserRoles;
      if (userRoles.indexOf(roleName) > -1) {
        return true;
      }
    }
    
    return false;
  }

  getUserRoles() {
    if (localStorage.getItem('token') != undefined) {
      var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      return payLoad.role
    } else {
      return [];
    }
  }

  getEnumName(objValue: any, enumList: any[], valueProperty?: string, namePropertyString?: string) {
    if (objValue != null) {
      if (!valueProperty && !namePropertyString) {
        //console.log(enumList);
        return this.lodashFindBy(enumList, 'value', objValue).name;
      } else {
        return this.lodashFindBy(enumList, valueProperty, objValue)[namePropertyString];
      }
    }
  }

  generateUUID() {
    return UUID.UUID();
  }

  getFormatedStringFromDays(numberOfDays) {
    if (numberOfDays == 0) {
      return "0 days";
    }

    var years = Math.floor(numberOfDays / 365);
    var months = Math.floor(numberOfDays % 365 / 30);
    var days = Math.floor(numberOfDays % 365 % 30);

    var yearsDisplay = years > 0 ? years + (years == 1 ? " year" : " years") : "";
    var monthsDisplay = months > 0 ? months + (months == 1 ? " month" : " months") : "";
    var daysDisplay = days > 0 ? days + (days == 1 ? " day" : " days") : "";

    if (years >= 1) {
      return yearsDisplay;
    } else if (months >= 1) {
      return monthsDisplay;
    } else if (days >= 1) {
      return daysDisplay;
    }
    //return yearsDisplay + monthsDisplay + daysDisplay;
  }
}
