<div [hidden]="appService.isLoading | async">
  <fieldset>
    <legend>Device scan</legend>

    <!--<app-device-scanner></app-device-scanner>-->

    <ng-container>
      <button class="form-execute-buttons" type="button" color="primary" mat-raised-button (click)="loadFields('patientOnECMO')">Scan equipment (patient currently on ECMO)</button>
      <button class="form-execute-buttons" type="button" color="primary" mat-raised-button (click)="loadFields('patientWasOnECMO')">Scan equipment (patient was previously on ECMO)</button>
      <button class="form-execute-buttons" type="button" color="primary" mat-raised-button (click)="loadFields('deviceFutureUse')">Scan equipment (dry circuit for future use)</button>
      <button [routerLink]="['/patientsanddevices/']" class="form-execute-buttons" type="button" color="primary" mat-raised-button>Patients with scanned devices</button>
    </ng-container>

    <div *ngIf="optionSelected != null">
      <form autocomplete="off" (submit)="save()" [formGroup]="form">

        <div class="control-item" *ngIf="optionSelected == 2">
          <label for="PatientUniqueId" [class.required-field]="form.controls['PatientUniqueId'].hasError('required')">UniqueId:</label>
          <br />
          <mat-form-field appearance="outline" class="control-item">
            <input matInput type="text" maxlength="20" required id="PatientUniqueId" name="PatientUniqueId" formControlName="PatientUniqueId" class="mat-input">
          </mat-form-field>
          <div class="hard-error" *ngIf="form.controls['PatientUniqueId'].hasError('required')">This field is required!</div>
        </div>

        <div class="control-item" *ngIf="optionSelected == 1 || optionSelected == 2">
          <label [class.required-field]="form.controls['Sex'].hasError('required')">Sex:</label>
          <br />
          <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
            <mat-select hideSingleSelectionIndicator required name="Sex" id="Sex" placeholder="Please select" formControlName="Sex">
              <mat-option>Please select</mat-option>
              <mat-option *ngFor="let sex of appService.enumSex" [value]="sex.value">
                {{sex.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="hard-error" *ngIf="form.controls['Sex'].hasError('required')">This field is required!</div>
        </div>

        <div class="control-item" *ngIf="optionSelected == 1 || optionSelected == 2">
          <label [class.required-field]="form.controls['BirthDate'].hasError('required')">Birthdate: {{form.controls['BirthDate'].value}}</label>
          <br />
          <elso-datetime-picker [model]="form.get('BirthDate')" id="BirthDate"></elso-datetime-picker>
          <div class="hard-error" *ngIf="form.controls['BirthDate'].hasError('required')">This field is required!</div>
        </div>

        <div class="control-item" *ngIf="optionSelected == 2">
          <label [class.required-field]="form.controls['TimeOn'].hasError('required')">Time on: {{form.controls['TimeOn'].value}}</label>
          <br />
          <elso-datetime-picker [model]="form.get('TimeOn')" id="TimeOn"></elso-datetime-picker>
          <div class="hard-error" *ngIf="form.controls['TimeOn'].hasError('required')">This field is required!</div>
        </div>

        <div class="control-item" *ngIf="optionSelected == 2">
          <label [class.required-field]="form.controls['TimeOff'].hasError('required')">Time off: {{form.controls['TimeOff'].value}}</label>
          <br />
          <elso-datetime-picker [model]="form.get('TimeOff')" id="TimeOff"></elso-datetime-picker>
          <div class="hard-error" *ngIf="form.controls['TimeOff'].hasError('required')">This field is required!</div>
        </div>

        <div class="control-item" *ngIf="optionSelected == 3">
          <label for="DeviceScanSetName" [class.required-field]="form.controls['DeviceScanSetName'].hasError('required')">Equipment group:</label>
          <br />
          <mat-form-field appearance="outline" class="control-item">
            <input matInput type="text" maxlength="20" required id="DeviceScanSetName" name="DeviceScanSetName" formControlName="DeviceScanSetName" class="mat-input">
          </mat-form-field>
          <div class="hard-error" *ngIf="form.controls['DeviceScanSetName'].hasError('required')">This field is required!</div>
        </div>


      </form>
      <button class="form-execute-buttons" type="button" color="primary" mat-raised-button (click)="openScanner()" [disabled]="form.invalid">{{buttonLabel}}</button>

    </div>

  </fieldset>
  <button [routerLink]="['/home']" class="form-execute-buttons" type="button" color="primary" mat-raised-button>Back</button>
</div>
