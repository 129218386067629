import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { MatMomentDateAdapterOptions } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
// TODO(mmalerba): See if we can clean this up at some point.
//import * as _moment from 'moment';
import _moment from 'moment';

// tslint:disable-next-line:no-duplicate-imports
// @ts-ignore
import { default as _rollupMoment, Moment, MomentFormatSpecification, MomentInput } from 'moment';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { DateTimeFormatService } from '../services/datetime-format.service';

const moment = _rollupMoment || _moment;

export interface OwlMomentDateAdapterOptions {

  strict?: boolean;

  useUtc?: boolean;
}


//export const CUSTOM_DATE_TIME_FORMATS = {
//  fullPickerInput: 'YYYY-MM-DD HH:mm:ss',
//  parseInput: 'YYYY-MM-DD HH:mm:ss',
//  datePickerInput: 'YYYY-MM-DD HH:mm:ss',
//  timePickerInput: 'LT',
//  monthYearLabel: 'MMM YYYY',
//  dateA11yLabel: 'LL',
//  monthYearA11yLabel: 'MMMM YYYY'
//};

export const MAT_MOMENT_DATE_ADAPTER_OPTIONS = new InjectionToken<MatMomentDateAdapterOptions>(
  'MAT_MOMENT_DATE_ADAPTER_OPTIONS', {
  providedIn: 'root',
  factory: MAT_MOMENT_DATE_ADAPTER_OPTIONS_FACTORY
});

export function MAT_MOMENT_DATE_ADAPTER_OPTIONS_FACTORY(): MatMomentDateAdapterOptions {
  return {
    //useUtc: true  //comtented on 01/03/2022 21:19
    //useUtc: false //comtented on 04/18/2024 19:53
    useUtc: true //added on 04/18/2024 19:53

  };
}

function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

@Injectable()
export class CustomOwlDateTimeAdapter extends DateTimeAdapter<Moment> {
  private _localeData: {
    firstDayOfWeek: number,
    longMonths: string[],
    shortMonths: string[],
    dates: string[],
    longDaysOfWeek: string[],
    shortDaysOfWeek: string[],
    narrowDaysOfWeek: string[]
  };

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string, private _dateTimeService: DateTimeFormatService,
    @Optional() @Inject(MAT_MOMENT_DATE_ADAPTER_OPTIONS)
    private _options?: MatMomentDateAdapterOptions) {

    super();
    this.setLocale(dateLocale || moment.locale());
  }

  setLocale(locale: string) {
    super.setLocale(locale);
    const momentLocaleData = moment.localeData(locale);
    this._localeData = {
      firstDayOfWeek: momentLocaleData.firstDayOfWeek(),
      longMonths: momentLocaleData.months(),
      shortMonths: momentLocaleData.monthsShort(),
      dates: range(31, (i) => this.createDate(2017, 0, i + 1).format('D')),
      longDaysOfWeek: momentLocaleData.weekdays(),
      shortDaysOfWeek: momentLocaleData.weekdaysShort(),
      narrowDaysOfWeek: momentLocaleData.weekdaysMin(),
    };
  }

  getYear(date: Moment): number {
    return this.clone(date).year();
  }

  getMonth(date: Moment): number {
    return this.clone(date).month();
  }

  getDate(date: Moment): number {
    return this.clone(date).date();
  }

  getDayOfWeek(date: Moment): number {
    return this.clone(date).day();
  }

  getDay(date: _moment.Moment): number {
    return this.clone(date).day();
    //return date.day();
  }
  getHours(date: _moment.Moment): number {
    return date.hours();
  }
  getMinutes(date: _moment.Moment): number {
    return date.minutes();
  }
  getSeconds(date: _moment.Moment): number {
    return date.seconds();
  }
  getTime(date: _moment.Moment): number {
    //return
    //console.log('getTime', date);
    //return moment();

    // @ts-ignore
    return this._createMoment().locale(this.locale);//moment();
  }
  differenceInCalendarDays(dateLeft: _moment.Moment, dateRight: _moment.Moment): number {
    //console.log('differenceInCalendarDays: ', dateLeft.diff(dateRight, 'days'));
    if (dateLeft != null && dateRight != null)
      return dateLeft.diff(dateRight, 'days');
    else
      return null
  }
  isEqual(dateLeft: _moment.Moment, dateRight: _moment.Moment): boolean {
    //console.log('isSame: ', dateLeft, dateRight);
    if (dateLeft != null && dateRight != null) {
      return dateLeft.toDate().getTime() === dateRight.toDate().getTime()
      //return dateLeft.isSame(dateRight);
    }
    else
      return false;
  }
  isSameDay(dateLeft: _moment.Moment, dateRight: _moment.Moment): boolean {
    if (dateLeft != null && dateRight != null) {
      //return dateLeft.isSame(dateRight);
      dateLeft.toDate().setHours(0, 0, 0, 0);
      dateRight.toDate().setHours(0, 0, 0, 0);
      return dateLeft.toDate().getTime() === dateRight.toDate().getTime();
    }
    else
      return false;
  }
  setHours(date: _moment.Moment, amount: number): _moment.Moment {
    //const result = this.clone(date);
    //this.setHour(result, amount);
    //return result;
    if (amount != 24 && amount != - 1) {//if added on 11-07-2023. This if should prevent datepicker to increase/decrease day by increasing hours after 23, or reducing after 00.
      date.hours(amount);
    }
    //console.log('setHours: ', amount);
    return this._createMoment(date).locale(this.locale);
  }
  setMinutes(date: _moment.Moment, amount: number): _moment.Moment {
    // @ts-ignore
    if (amount != 60 && amount != - 1) {//if added on 11-07-2023. This if should prevent datepicker to increase/decrease hour by increasing minutes after 59, or reducing after 00.
    //console.log(amount);
     date.minutes(amount);
    }
    //console.log('setMinutes: ', amount);
    return this._createMoment(date).locale(this.locale);
  }
  setSeconds(date: _moment.Moment, amount: number): _moment.Moment {
    date.seconds(amount);
    // @ts-ignore
    return this._createMoment(date).locale(this.locale);
  }
  now(): _moment.Moment {
    // @ts-ignore
    return moment().utc(true); //this._createMoment().locale(this.locale);//commented on 04-23-2024 because it was returning date without gmt added/reduced when datepicker is first open without value.
  }

  

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    // Moment.js doesn't support narrow month names, so we just use short if narrow is requested.
    return style === 'long' ? this._localeData.longMonths : this._localeData.shortMonths;
  }

  getDateNames(): string[] {
    return this._localeData.dates;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'long') {
      return this._localeData.longDaysOfWeek;
    }
    if (style === 'short') {
      return this._localeData.shortDaysOfWeek;
    }
    return this._localeData.narrowDaysOfWeek;
  }

  getYearName(date: Moment): string {
    return this.clone(date).format('YYYY');
  }

  getFirstDayOfWeek(): number {
    return this._localeData.firstDayOfWeek;
  }

  getNumDaysInMonth(date: Moment): number {
    return this.clone(date).daysInMonth();
  }

  clone(date: Moment): Moment {
    //console.log(date);
    return date.clone().locale(this.locale);
  }

  createDate(year: number, month: number, date: number): Moment {

    //console.log(date);
    if (month < 0 || month > 11) {
      throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    const result = this._createMoment({ year, month, date }).locale(this.locale);
    if (!result.isValid()) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }

    return result;
  }

  today(): Moment {
    // @ts-ignore
    return this._createMoment().locale(this.locale);
  }

  parse(value: any, parseFormat: string | string[]): Moment | null {
    //console.log('parse: ', value, parseFormat)
    if (value && typeof value === 'string') {
      return this._createMoment(value, parseFormat, this.locale);
    }
    return value ? this._createMoment(value).locale(this.locale) : null;
  }

  public format(date: moment.Moment, displayFormat: string): string {//this is string value in date picker field, just how date looks, not real value of model
    //console.log('format: ', date, displayFormat);
    const locale = this._dateTimeService.locale;
    const format = this._dateTimeService.format;
    
    const result = date.utc().locale(locale).format(format);
    //console.log(date.utcOffset());
    //console.log(locale);
    //console.log(format);
    //console.log(result);
    //console.log(`Reading date [local: '${locale}'; format: '${format}'; result: '${result}']`);

    return result;

    //return date.toString();
  }



  addCalendarYears(date: Moment, years: number): Moment {
    return this.clone(date).add({ years });
  }

  addCalendarMonths(date: Moment, months: number): Moment {
    return this.clone(date).add({ months });
  }

  addCalendarDays(date: Moment, days: number): Moment {
    return this.clone(date).add({ days });
  }

  toIso8601(date: Moment): string {
    //console.log('toIso8601:', date);
    return this.clone(date).format();
  }

  deserialize(value: any): Moment | null {
    //console.log('desirialize: ', value);
    let date;
    if (value instanceof Date) {
      date = this._createMoment(value).locale(this.locale);
      //console.log('date: ', date);
    } else if (this.isDateInstance(value)) {
      return this.clone(value);
    }
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      //console.log(new Date(value).toTimeString())
      //console.log(moment(value).utcOffset(0, true).format());

      //var test = 
      date = this._createMoment(value, moment.ISO_8601).locale(this.locale);
    }
    if (date && this.isValid(date)) {
      return this._createMoment(date).locale(this.locale);
    }
    return super.deserialize(value);
  }

  isDateInstance(obj: any): boolean {
    //console.log('isDateInstance: ', obj);
    return moment.isMoment(obj);
  }

  isValid(date: Moment): boolean {
    //console.log('isValid: ', this.clone(date).isValid());
    return this.clone(date).isValid();
  }

  invalid(): Moment {
    //console.log('invalid');
    return moment.invalid();
  }

  //getHour(date: _moment.Moment): number {
  //  return date.hours();
  //}
  //getMinute(date: _moment.Moment): number {
  //  return date.minutes();
  //}
  //getSecond(date: _moment.Moment): number {
  //  return date.seconds();
  //}

  setHour(date: _moment.Moment, value: number): void {
    //console.log('setHour: ', date, value);
    date.hours(value);
  }
  //setMinute(date: _moment.Moment, value: number): void {
  //  console.log('setMinute: ', value);
  //  date.minutes(value)
  //}
  //setSecond(date: _moment.Moment, value: number): void {
  //  date.seconds(value);
  //}

  private _createMoment(
    date: MomentInput,
    format?: MomentFormatSpecification,
    locale?: string,
  ): Moment {
    const { strict, useUtc }: OwlMomentDateAdapterOptions = this._options || {};
    //console.log('_createMoment: ', moment.utc(date, format, locale, strict));
    return useUtc
      ? moment.utc(date, format, locale, strict)
      : moment(date, format, locale, strict);
  }
}
