<div>

  <button (click)="convertHTMLtoPDF()">
    test
  </button>


  <div id="pdfContentHolder" style="margin:auto; width: 720px; height: 800px;">
    <header style="display:none; margin-top:24px;">
      <table width="100%">
        <tr>
          <td style="text-align: right; font-size: 11px; color: #555">International Summary - {{getMonthYearHeader()}}</td>
        </tr>
      </table>
    </header>
    <table width="100%">
      <tr>
        <td><h1>ECLS Registry Report</h1><br />International Summary<br />{{getMonthYearHeader()}}<br /><span>Report data through 2020</span><!--<br /><span data-ng-if="isJulyReport();">For July reports, the current year is reported as a partial year only</span>--></td>
        <td style="text-align:center;"><img id="headerLogo" [src]="transformBase64Image('logo')" style="width: 150px; height:100px;" src="" /></td>
        <td style="text-align:right">
          <p>Extracorporeal Life Support Organization</p>
          <p>3001 Miller Rd</p>
          <p>Ann Arbor, MI 48103 USA</p>
        </td>
      </tr>
    </table>
       <div style="margin-top: 10px;" class="blackHeaderHolder">Centers</div>
    <div id="centersChartcontainer" class="internationalBarCharts"></div>
    <!--<div style="margin-top: 10px;" class="blackHeaderHolder">Overall Outcomes</div>

    <report-overall-outcome [reportData]="this.reportData?.InternationalReportOverallOutcomesResult"></report-overall-outcome>

    <div style="margin-top: 10px;" class="blackHeaderHolder">Centers</div>
    <div id="centersChartcontainer" class="internationalBarCharts"></div>


    <div style="page-break-after: always;"></div>
    <div style="margin-top: 10px;" class="blackHeaderHolder">Overall Outcomes from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</div>
    <report-overall-outcome [reportData]="this.reportData?.InternationalReportOverallOutcomesFiveYearsResult"></report-overall-outcome>

    <div style="margin-top: 10px;" class="blackHeaderHolder">Centers by region</div>
    <div id="centersChartRegioncontainer" class="internationalBarCharts"></div>
    <div style="page-break-after: always;"></div>-->

    <!--<div style="margin-top: 10px;" class="blackHeaderHolder">Annual Center Volume in {{getYearFooter() - 1}}</div>--><!--January-->
    <!--<div style="margin-top: 10px;" class="blackHeaderHolder">Annual Center Volume in {{getYearFooter()}}</div>--><!--July-->
    <!--<div id="borderAll" style="width: 720px; height: 800px; margin: 0 auto;">
      <div id="centersChartBoxPlotAll" style="min-width: 600px; height: 400px; max-width: 720px; margin: 0 auto; float: left;"></div>
      <div id="legendAll" style="min-width: 100px; height: 100px; max-width: 100px; margin: 170px 0px 100px 5px; float: right;">
        <img id="legAllIm" [src]="transformBase64Image()" style="width: 100px; height:100px;" src="" />
      </div>
    </div>

    <div id="clearAll" style="clear:both;"></div>
    <br />
    <div id="borderAllChildren" style="width: 720px; height: 800px; margin: 0 auto;">
      <div id="centersChartBoxPlotChildren" style="min-width: 600px; height: 400px; max-width: 720px; margin: 0 auto; float:left;"></div>
      <div id="legendAllChildren" style="min-width: 100px; height: 100px; max-width: 100px; margin: 170px 0px 100px 5px; float: right;">
        <img id="legAllImChildren" [src]="transformBase64Image()" style="width: 100px; height:100px;" src="" />
      </div>
    </div>

    <div style="page-break-after: always;"></div>
    <div id="borderAllAdult" style="width: 720px; height: 800px; margin: 0 auto;">
      <div id="centersChartBoxPlotAdults" style="min-width: 600px; height: 400px; max-width: 720px; margin: 0 auto; float:left;"></div>
      <div id="legendAllAdults" style="min-width: 100px; height: 100px; max-width: 100px; margin: 170px 0px 100px 5px; float: right;">
        <img id="legAllImAdults" [src]="transformBase64Image()" style="width: 100px; height:100px;" src="" />
      </div>
    </div>


    <div id="centersChartChildAdVV" class="internationalBarCharts"></div>
    <div id="carotidArteryChart" class="internationalBarCharts"></div>
    <div style="page-break-after: always;"></div>

    <div style="page-break-after: always;"></div>
    <div style="margin-top: 10px;" class="blackHeaderHolder">Neonatal Respiratory (0-28 days)</div>
    <div id="arnrChartcontainer" class="internationalBarCharts"></div>-->

    <br />
    <h2>Neonatal Respiratory Runs by Year</h2>
    <report-runs-by-year [reportData]="this.reportData?.InternationalReportNeoRespRunsByYearResult"></report-runs-by-year>
    <!--<br />
    <h2>Neonatal Respiratory Runs by Diagnosis from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-diagnose-from-year [reportData]="this.reportData?.InternationalReportNeoRespRunsByDiagResult"></report-diagnose-from-year>

    <div style="page-break-after: always;"></div>
    <h2>Initial Neonatal Respiratory Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year [reportData]="this.reportData?.InternationalReportNeoRespSuppModeDetOldResult"></report-support-mode-from-year>
    <br />

    <h2>Runs with Conversions - Neonatal Respiratory Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year-conversion [reportData]="this.reportData?.InternationalReportNeoRespSuppModeDetResult"></report-support-mode-from-year-conversion>
    <br />

    <h2>Neonatal Respiratory Complications from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-complication-from-year [reportData]="this.reportData?.InternationalReportNeoRespCompResult"></report-complication-from-year>
    <div style="page-break-after: always;"></div>
    <div style="margin-top: 10px;" class="blackHeaderHolder">Pediatric Respiratory (>28 days and <18 years)</div>
    <div id="arprChartcontainer" class="internationalBarCharts"></div>

    <h2>Pediatric Respiratory Runs by Year</h2>
    <report-runs-by-year [reportData]="this.reportData?.InternationalReportPedRespRunsByYearResult"></report-runs-by-year>
    <br />
    <h2>Pediatric Respiratory Runs by Diagnosis from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-diagnose-from-year [reportData]="this.reportData?.InternationalReportPedRespRunsByDiagResult"></report-diagnose-from-year>

    <div style="page-break-after: always;"></div>
    <h2>Initial Pediatric Respiratory Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year [reportData]="this.reportData?.InternationalReportPedRespSuppModeDetOldResult"></report-support-mode-from-year>
    <br />

    <h2>Runs with Conversions - Pediatric Respiratory Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year-conversion [reportData]="this.reportData?.InternationalReportPedRespSuppModeDetResult"></report-support-mode-from-year-conversion>
    <br />

    <h2>Pediatric Respiratory Complications from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-complication-from-year [reportData]="this.reportData?.InternationalReportPedRespCompResult"></report-complication-from-year>
    <div style="page-break-after: always;"></div>

    <div style="margin-top: 10px;" class="blackHeaderHolder">Adult Respiratory (18 years and over)</div>
    <div id="ararChartcontainer" class="internationalBarCharts"></div>
    <br />

    <h2>Adult Respiratory Runs by Year</h2>
    <report-runs-by-year [reportData]="this.reportData?.InternationalReportAdRespRunsByYearResult"></report-runs-by-year>
    <br />

    <h2>Adult Respiratory Runs by Diagnosis from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-diagnose-from-year [reportData]="this.reportData?.InternationalReportAdRespRunsByDiagResult"></report-diagnose-from-year>

    <div style="page-break-after: always;"></div>
    <h2>Initial Adult Respiratory Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year [reportData]="this.reportData?.InternationalReportAdRespSuppModeDetOldResult"></report-support-mode-from-year>
    <br />

    <h2>Runs with Conversions - Adult Respiratory Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year-conversion [reportData]="this.reportData?.InternationalReportAdRespSuppModeDetResult"></report-support-mode-from-year-conversion>
    <br />-->

    <!--<div style="page-break-after: always;"></div>-->
    <!--<h2>Adult Respiratory Complications from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-complication-from-year [reportData]="this.reportData?.InternationalReportAdRespCompResult"></report-complication-from-year>

    <div style="page-break-after: always;"></div>
    <div style="margin-top: 10px;" class="blackHeaderHolder">Neonatal Cardiac (0-28 days)</div>
    <div id="acnrChartcontainer" class="internationalBarCharts"></div>
    <br />

    <h2>Neonatal Cardiac Runs by Year</h2>
    <report-runs-by-year [reportData]="this.reportData?.InternationalReportNeoCardRunsByYearResult"></report-runs-by-year>
    <br />
    <h2>Neonatal Cardiac Runs by Diagnosis from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-diagnose-from-year [reportData]="this.reportData?.InternationalReportNeoCardRunsByDiagResult"></report-diagnose-from-year>
    <br />
    <h2>Neonatal Cardiac Runs by Congenital Diagnosis from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-diagnose-from-year [reportData]="this.reportData?.InternationalReportNeoCardRunsByCongDiagResult"></report-diagnose-from-year>
    <br />
    <h2>Initial Neonatal Cardiac Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year [reportData]="this.reportData?.InternationalReportNeoCardSuppModeDetOldResult"></report-support-mode-from-year>
    <br />
    <h2>Runs with Conversions - Neonatal Cardiac Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year-conversion [reportData]="this.reportData?.InternationalReportNeoCardSuppModeDetResult"></report-support-mode-from-year-conversion>
    <br />

    <h2>Neonatal Cardiac Complications from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-complication-from-year [reportData]="this.reportData?.InternationalReportNeoCardCompResult"></report-complication-from-year>

    <div style="page-break-after: always;"></div>
    <div style="margin-top: 10px;" class="blackHeaderHolder">Pediatric Cardiac (>28 days and <18 years)</div>
    <div id="acprChartcontainer" class="internationalBarCharts"></div>
    <br />

    <h2>Pediatric Cardiac Runs by Year</h2>
    <report-runs-by-year [reportData]="this.reportData?.InternationalReportPedCardRunsByYearResult"></report-runs-by-year>
    <br />
    <h2>Pediatric Cardiac Runs by Diagnosis from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-diagnose-from-year [reportData]="this.reportData?.InternationalReportPedCardRunsByDiagResult"></report-diagnose-from-year>
    <br />
    <h2>Pediatric Cardiac Runs by Congenital Diagnosis from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-diagnose-from-year [reportData]="this.reportData?.InternationalReportPedCardRunsByCongDiagResult"></report-diagnose-from-year>
    <br />
    <h2>Initial Pediatric Cardiac Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year [reportData]="this.reportData?.InternationalReportPedCardSuppModeDetOldResult"></report-support-mode-from-year>
    <br />
    <h2>Runs with Conversions - Pediatric Cardiac Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year-conversion [reportData]="this.reportData?.InternationalReportPedCardSuppModeDetResult"></report-support-mode-from-year-conversion>
    <br />

    <h2>Pediatric Cardiac Complications from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-complication-from-year [reportData]="this.reportData?.InternationalReportPedCardCompResult"></report-complication-from-year>
    <div style="page-break-after: always;"></div>
    <div style="margin-top: 10px;" class="blackHeaderHolder">Adult Cardiac (18 years and over)</div>
    <div id="acarChartcontainer" class="internationalBarCharts"></div>
    <br />
    <h2>Adult Cardiac Runs by Year</h2>
    <report-runs-by-year [reportData]="this.reportData?.InternationalReportAdCardRunsByYearResult"></report-runs-by-year>
    <br />
    <h2>Adult Cardiac Runs by Diagnosis from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-diagnose-from-year [reportData]="this.reportData?.InternationalReportAdCardRunsByDiagResult"></report-diagnose-from-year>
    <br />
    <h2>Adult Cardiac Runs by Congenital Diagnosis from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-diagnose-from-year [reportData]="this.reportData?.InternationalReportAdCardRunsByCongDiagResult"></report-diagnose-from-year>-->
    <!--<br />-->
    <!--<div style="page-break-after: always;"></div>
    <h2>Initial Adult Cardiac Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year [reportData]="this.reportData?.InternationalReportAdCardSuppModeDetOldResult"></report-support-mode-from-year>
    <br />

    <h2>Runs with Conversions - Adult Cardiac Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year-conversion [reportData]="this.reportData?.InternationalReportAdCardSuppModeDetResult"></report-support-mode-from-year-conversion>
    <br />

    <h2>Adult Cardiac Complications from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-complication-from-year [reportData]="this.reportData?.InternationalReportAdCardCompResult"></report-complication-from-year>

    <div style="page-break-after: always;"></div>
    <div style="margin-top: 10px;" class="blackHeaderHolder">Neonatal ECPR (0-28 days)</div>
    <div id="aecprnrChartcontainer" class="internationalBarCharts"></div>
    <br />

    <h2>Neonatal ECPR Runs by Year</h2>
    <report-runs-by-year [reportData]="this.reportData?.InternationalReportNeoECPRRunsByYearResult"></report-runs-by-year>
    <br />
    <h2>Initial Neonatal ECPR Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year [reportData]="this.reportData?.InternationalReportNeoECPRSuppModeDetOldResult"></report-support-mode-from-year>
    <br />

    <h2>Runs with Conversions - Neonatal ECPR Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year-conversion [reportData]="this.reportData?.InternationalReportNeoECPRSuppModeDetResult"></report-support-mode-from-year-conversion>
    <br />

    <h2>Neonatal ECPR Complications from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-complication-from-year [reportData]="this.reportData?.InternationalReportNeoECPRCompResult"></report-complication-from-year>

    <div style="page-break-after: always;"></div>
    <div style="margin-top: 10px;" class="blackHeaderHolder">Pediatric ECPR (>28 days and <18 years)</div>
    <div id="aecprprChartcontainer" class="internationalBarCharts"></div>
    <br />
    <h2>Pediatric ECPR Runs by Year</h2>
    <report-runs-by-year [reportData]="this.reportData?.InternationalReportPedECPRRunsByYearResult"></report-runs-by-year>
    <br />
    <h2>Initial Pediatric ECPR Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year [reportData]="this.reportData?.InternationalReportPedECPRSuppModeDetOldResult"></report-support-mode-from-year>
    <br />

    <h2>Runs with Conversions - Pediatric ECPR Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year-conversion [reportData]="this.reportData?.InternationalReportPedECPRSuppModeDetResult"></report-support-mode-from-year-conversion>
    <br />

    <h2>Pediatric ECPR Complications from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-complication-from-year [reportData]="this.reportData?.InternationalReportPedECPRCompResult"></report-complication-from-year>


    <div style="page-break-after: always;"></div>
    <div style="margin-top: 10px;" class="blackHeaderHolder">Adult ECPR (18 years and over)</div>
    <div id="aecprarChartcontainer" class="internationalBarCharts"></div>

    <h2>Adult ECPR Runs by Year</h2>
    <report-runs-by-year [reportData]="this.reportData?.InternationalReportAdECPRRunsByYearResult"></report-runs-by-year>
    <br />
    <h2>Initial Adult ECPR Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year [reportData]="this.reportData?.InternationalReportAdECPRSuppModeDetOldResult"></report-support-mode-from-year>
    <br />

    <h2>Runs with Conversions - Adult ECPR Support Mode Details from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-support-mode-from-year-conversion [reportData]="this.reportData?.InternationalReportAdECPRSuppModeDetResult"></report-support-mode-from-year-conversion>
    <br />

    <h2>Adult ECPR Complications from {{ getYearForDiagnosis() }} to 2020--><!--Present--><!--</h2>
    <report-complication-from-year [reportData]="this.reportData?.InternationalReportAdECPRCompResult"></report-complication-from-year>-->

    
  </div>
</div>
