<div [hidden]="appService.isLoading | async">
  <span>ECLS Runs:</span>
  <br />
  <br />
  <label>Search: &nbsp;</label>
  <input type="text" name="query" [(ngModel)]="query" placeholder="Search by uniqueid" />
  <br />
  <br />
  <div class="elso-grid">
    <div class="elso-grid-row" *ngFor="let run of (allRuns | elsoGridFilter:query |
                         slice: paginatorService.lowValue : paginatorService.highValue)">
      <div>
        <button class="form-execute-buttons" (click)="deleteRun(run.RunId)" type="button" color="primary" mat-raised-button>
          <mat-icon>delete</mat-icon>
          Delete run
        </button>
        <button class="form-execute-buttons" (click)="deletePatient(run.PatientId)" type="button" color="primary" mat-raised-button>
          <mat-icon>delete</mat-icon>
          Delete patient
        </button>
      </div>
      <div>
        <span class="elso-grid-label">Unique id: </span>
        <span>{{run.UniqueId}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Run no: </span>
        <span>{{run.RunNo}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Birthdate: </span>
        <span>{{run.Birthdate | dateFormat}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Age: </span>
        <span>{{appService.getFormatedStringFromDays(run.Age)}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Time on: </span>
        <span>{{run.TimeOn | dateFormat}}</span>
      </div>
      <div>
        <span class="elso-grid-label">Time off: </span>
        <span>{{run.TimeOff | dateFormat}}</span>
      </div>
    </div>
  </div>

  <br />
  <br />
  <mat-paginator *ngIf="allRuns" [length]="allRuns.length" pageSize=5
                 (page)="paginatorService.getPaginatorData($event)" [pageSizeOptions]="[5, 20, 30]"
                 showFirstLastButtons="false">
  </mat-paginator>
</div>
