import { Component, AfterViewInit, ViewChild, NgZone } from '@angular/core';
//import { MdePopoverTrigger } from '@material-extended/mde';
import { ForTooltipComponent } from '../tooltip/for-tooltip.component';
import * as Highcharts from 'highcharts';
import { DataService } from '../../services/data.service';
import { AppService } from '../../services/app.service';
import { PaginatorService } from '../../services/paginator.service';
import { LoadingService } from '../../services/loading.service';
import { BehaviorSubject, } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-runPatientDeletion',
  templateUrl: './runPatientDeletion.component.html',
})
export class RunPatientDeletionComponent {
  public allRuns: any;
  public query: string;

  constructor(private router: Router, private dataService: DataService, public appService: AppService, public paginatorService: PaginatorService) {
    this.dataService.getall('Home/GetAllRunsForCenter').subscribe(
      (res: any) => {
        //console.log(res.data);
        this.allRuns = res.data;
      },
      err => {
        console.log(err);
      }
    )
  }

  ngOnInit() {
    this.appService.Run = null;
    this.appService.Patient = null;
  }

  deleteRun(runId: string) {
    var deleteRun = true;
    var tempRun = this.appService.lodashFindBy(this.allRuns, 'RunId', runId);

    var tempRuns = this.appService.lodashFilterBy(this.allRuns, 'PatientId', tempRun.PatientId);
    if (tempRuns.length == 1) {
      deleteRun = confirm("This is the last run for patient. If you delete this run, patient will also be deleted. Please confirm.");
    }

    if (deleteRun == true) {
      this.dataService.delete('Runs/DeleteRun', 'RunId', runId)?.subscribe(
        (res: any) => {
          //console.log(runId);
          this.appService.lodashRemoveBy(this.allRuns, 'RunId', runId);
          //console.log(this.allRuns);
          this.query = "";
        },
        err => {
          console.log(err);
        }
      );
    }

  }
  
  deletePatient(patientId: string) {

    this.dataService.delete('Patients/DeletePatient', 'PatientId', patientId)?.subscribe(
      (res: any) => {
        this.appService.lodashRemoveBy(this.allRuns, 'PatientId', patientId);
        //console.log(this.allRuns);
        this.query = "";
      },
      err => {
        console.log(err);
      }
    );
  }
  
}
