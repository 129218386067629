<div class="deviceScanner">
  <div>
    <button type="button" class="form-execute-buttons" color="primary" mat-raised-button (click)="showScanner()" [disabled]="disabled">Video Decode</button>
    &nbsp;&nbsp;
    <button type="button" class="form-execute-buttons" color="primary" mat-raised-button (click)="showImgDecode()" [disabled]="disabled">Image Decode</button>
  </div>
  <div class="container" *ngIf="disabled != true">
    <app-video-decode (customOnChange)="listenForChildChange($event)" *ngIf="bShowScanner"></app-video-decode>
    <app-image-decode (customOnChange)="listenForChildChange($event)" *ngIf="bShowImgDecode"></app-image-decode>
  </div>
</div>
