import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../classes/user';
import { DataService } from '../../services/data.service'

import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { AppService } from '../../services/app.service';
import { DateTimeFormatService } from '../../services/datetime-format.service';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private dataService: DataService, private toastr: ToastrService, private router: Router, public appService: AppService, private dateTimeFormatService: DateTimeFormatService
     ,public auth: AuthService
    ) { }

  systemVersion: any;
  showLoginButton = false;

  ngOnInit(): void {
    this.showLoginButton = false;

    this.systemVersion = false;
    this.resetTokens();    

    this.dataService.getall('Settings/GetSystemVersion').subscribe(
      (res: any) => {
        //console.log(res);
        this.systemVersion = res.data;
        if (!this.systemVersion.IsOffline) {

          localStorage.removeItem('auth0token');

          try {
            setTimeout(() => {//Timeout is a must for Brave/Firefox browsers to work properly with Auth0
              this.auth.getAccessTokenSilently().subscribe(
                (res: any) => {
                  localStorage.setItem('auth0token', res);
                  console.log(localStorage.getItem('auth0token'));
                  this.login();
                },
                err => {
                  this.showLoginButton = true;
                  console.log(err);
                }
              );
            }, 500);
         
          } catch (e){
            console.log('exception');
            console.log(e);
            throw e;
          }
          

          
        }

      },
      err => {
        console.log(err);
      }
    );

  }

  loginUserModel = new User('', '', 0, '');
  showTfaOptions = false;
  tfaCodeSent = false;
  showResetPassword = false;
  resetPasswordLink = '';
  fullName = '';

  public login() {
   // console.log(this.loginUserModel);
    this.dataService.login(this.loginUserModel).subscribe(
      (res: any) => {
       // console.log(res);

        if (res.resetLink) {
          this.showResetPassword = true;
          this.resetPasswordLink = res.resetLink;
          this.fullName = res.fullName;

          return;
        }

        //before token set, should check if some TFA message or error message is returned
        localStorage.setItem('token', res.data);
        this.toastr.success('Welcome to ELSO Registry', 'Authentication success.');

        //var tempToken = res.token;
        //console.log(tempToken);
        ////tempToken = tempToken.replace('e', 'w');
        //console.log(tempToken);
        //this.dataService.update('Users/CheckIfELSOTokenisValid', { 'Token': tempToken, DnnIpAddress: 'dnnIPaddress'}).subscribe(
        //  (res: any) => {
        //    console.log(res);
        //  },
        //  err => {
        //    console.log(err);
        //  }
        //);
        var tokenHeader = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('token') });
        //var tokenHeader = new Headers({ 'Authorization': 'Bearer ' + localStorage.getItem('token') });
        this.dataService.get('Users/GetUserProfile', null, null).subscribe(
          (res: any) => {
            // console.log(res);
            //need to set datetime format for user here
            localStorage.setItem('unitSystem', res.UnitSystem);
            localStorage.setItem('cultureSettings', res.DateTimeFormat);
            localStorage.setItem('cultureOptionSelected', res.CultureOptionSelected);
            localStorage.setItem('centerName', res.Name);
            localStorage.setItem('centerId', res.CenterId);
            localStorage.setItem('userName', res.UserName);

            this.appService.setUserProfile();
            /////
            this.setDateTimeFormatService(res.DateTimeFormat);

            this.dataService.setEnumsAndCenter();
            //console.log(this.appService.UserProfile);

              //this.router.navigateByUrl('/home')
            if (this.appService.isUserInRole('Host User') == true || this.appService.isUserInRole('Host User - Registry Only') == true
              || this.appService.isUserInRole('Multi Center User') == true || this.appService.isUserInRole('Multi Center XML Import') == true) {
                //get centers for user, show dd list
                this.dataService.getall('Users/GetCentersForUser').subscribe(
                  (res: any) => {
                    //this.centers = res.data;
                    //this.showCentersList = true;
                    this.appService.userCenters = res.data;

                    this.appService.centerQuery = this.appService.UserProfile.CenterName; //this.appService.lodashFindBy(this.appService.userCenters, 'CenterId', this.appService.UserProfile.CenterId).CenterId;

                    this.appService.filteredCenters = this.appService.userCenters;

                    //console.log(this.appService.centerQuery);
                    //console.log(this.appService.filteredCenters);

                    setTimeout(() => {///allow timeout for local storage to be set properly because of date format pipe display
                      this.router.navigateByUrl('/home');
                    }, 100);
                  },
                  err => {
                    console.log(err);
                  }
                );
              } else {
                setTimeout(() => {///allow timeout for local storage to be set properly because of date format pipe display
                  this.router.navigateByUrl('/home');
                }, 100);
              }           
          },
          err => {
            console.log(err);
          }
        );
        //this.router.navigateByUrl('/home')
      },
      err => {
        this.auth.logout();
        
        console.log(err);
        if (err.status == 400)
          this.toastr.error('Authentication failed.', err.error.message);
        else
          this.toastr.error('Unexpected error.');
      }
    )
    //return this.http.post()
  }

  setDateTimeFormatService(settings: any) {
    var cultureSettings = settings.split('|');
    var l = cultureSettings[0];
    var f = cultureSettings[1];
    var t = (cultureSettings[2] === 'true');
    this.dateTimeFormatService.locale = l;
    this.dateTimeFormatService.format = f;
    this.dateTimeFormatService.twelveHourFormat = t;
  }

  resetTokens() {
    localStorage.removeItem('cultureSettings');
    localStorage.removeItem('unitSystem');
    localStorage.removeItem('cultureOptionSelected');
    localStorage.removeItem('centerName');
    localStorage.removeItem('centerId');
    localStorage.removeItem('token');
  }

}
