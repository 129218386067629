<div [hidden]="appService.isLoading | async" style="height: 1000px; overflow-y: scroll; ">
  <br /><br /> <br />
  <div style="border: 5px solid #3F51B5; width: fit-content; border-radius: 15px; padding: 3%; margin: auto;">
    <form class="example-form" autocomplete="off" (submit)="submitForm()">
      <img style="width: 200px;" class="" src="../assets/images//elso-logo-big.png">
      <br />
      <br />
      <br />
      <div *ngIf="!showTfaOptions && !tfaCodeSent">
        <mat-form-field appearance="fill">
          <mat-label>Username</mat-label>
          <input matInput type="text" name="Username" [(ngModel)]="loginUserModel.Username">
        </mat-form-field>
        <br /> <br />
        <mat-form-field appearance="fill">
          <mat-label>Password</mat-label>
          <input matInput type="password" name="Password" [(ngModel)]="loginUserModel.Password">
        </mat-form-field>
        <br />
        <br />
        <button type="submit" color="primary" mat-raised-button>Login</button>
      </div>
      <div *ngIf="showTfaOptions">
        <mat-label class="red-note">Please select two factor authentication option to receive code:</mat-label>
        <br />
        <mat-radio-group matInput [(ngModel)]="loginUserModel.TFAoption" name="TFAoption">
          <mat-radio-button [value]="1">SMS&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button>
          <mat-radio-button [value]="2">Email&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button>
        </mat-radio-group>
        <br />
        <br />
        <button type="submit" color="primary" mat-raised-button [disabled]="!loginUserModel.TFAoption">Save option</button>
      </div>
      <div *ngIf="tfaCodeSent">
        <mat-form-field appearance="fill">
          <mat-label>TFA code:</mat-label>
          <input matInput type="text" [(ngModel)]="loginUserModel.TFAcode" name="TFAcode">
        </mat-form-field>
        <br />
        <br />
        <button type="submit" color="primary" mat-raised-button>Verify code</button>
      </div>
      <div *ngIf="showTfaOptions || tfaCodeSent">
        <br />
        <button type="button" color="primary" mat-raised-button (click)="resetForm()">Login using different account</button>
      </div>
    </form>
  </div>

</div>


