<!--Calendar and Timer-->
<mat-form-field class="elso-datetimepicker" *ngIf="!pickerType" appearance="fill" [owlDateTimeTrigger]="dt1">
  <input matInput readonly sectionName="this.sectionName" [id]="this.id" otherDateComparsion="this.otherDateComparsion"
         [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date time" [formControl]="this.model" (dateTimeChange)="changeEvent($event)">
  <owl-date-time #dt1 [hour12Timer]="dateTimeService.twelveHourFormat"></owl-date-time>
  <span style=" position: absolute; right: -12px; bottom: 10px;" class="trigger" [owlDateTimeTrigger]="dt1">
    <mat-icon class="mat-icon" matTooltip="Picker" matTooltipPosition="above" color="primary">calendar_today</mat-icon>
  </span>
</mat-form-field>
<!--Just Calendar or Timer-->
<mat-form-field class="elso-datetimepicker" *ngIf="pickerType == 'calendar' || pickerType == 'timer'" appearance="fill" [owlDateTimeTrigger]="dt1">
  <input matInput readonly sectionName="this.sectionName" [id]="this.id" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date time" [formControl]="model" (dateTimeChange)="changeEvent($event)">
  <owl-date-time #dt1 [startView]="'multi-years'"
                 (yearSelected)="chosenYearHandler($event)"
                 (monthSelected)="chosenMonthHandler($event)"
                 (daySelected)="chosenDayHandler($event, dt1)" dateFormat="'MM/DD/YYYY'"
                 [pickerType]="pickerType" [hour12Timer]="dateTimeService.twelveHourFormat"></owl-date-time>
  <span style=" position: absolute; right: -12px; bottom: 10px;" class="trigger" [owlDateTimeTrigger]="dt1">
    <mat-icon class="mat-icon" matTooltip="Picker" matTooltipPosition="above" color="primary">calendar_today</mat-icon>
  </span>
</mat-form-field>

&nbsp;&nbsp;<mat-icon *ngIf="model.value && disablePickerReset != 'true'" matTooltip="Reset value" matTooltipPosition="above" color="primary" (click)="resetDatePickerValue()">remove_circle</mat-icon>

<div class="hard-error" *ngFor="let error of HardErrors">{{error.ErrorMessage}}</div>
<div class="soft-error" *ngFor="let error of SoftErrors">{{error.ErrorMessage}}</div>


<!--Calendar and Timer-->
<!--<mat-form-field class="elso-datetimepicker" *ngIf="!pickerType" appearance="fill" [owlDateTimeTrigger]="dt1">
  <input matInput readonly sectionName="this.sectionName" [id]="this.id" otherDateComparsion="this.hardComparsion"
         [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date time" [formControl]="model" (ngModelChange)="changeEvent($event)">
  <owl-date-time #dt1 [hour12Timer]="dateTimeService.twelveHourFormat"></owl-date-time>
  <span style=" position: absolute; right: -12px; bottom: 10px;" class="trigger" [owlDateTimeTrigger]="dt1">
    <mat-icon class="mat-icon" matTooltip="Picker" matTooltipPosition="above" color="primary">calendar_today</mat-icon>
  </span>
</mat-form-field>-->
<!--Just Calendar or Timer-->
<!--<mat-form-field class="elso-datetimepicker" *ngIf="pickerType == 'calendar' || pickerType == 'timer'" appearance="fill" [owlDateTimeTrigger]="dt1">
  <input matInput readonly sectionName="this.sectionName" [id]="this.id" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date time" [formControl]="model" (ngModelChange)="changeEvent($event)">
  <owl-date-time #dt1 [startView]="'multi-years'"
                 (yearSelected)="chosenYearHandler($event)"
                 (monthSelected)="chosenMonthHandler($event)"
                 (daySelected)="chosenDayHandler($event, dt1)"
                 [pickerType]="pickerType" [hour12Timer]="dateTimeService.twelveHourFormat"></owl-date-time>
  <span style=" position: absolute; right: -12px; bottom: 10px;" class="trigger" [owlDateTimeTrigger]="dt1">
    <mat-icon class="mat-icon" matTooltip="Picker" matTooltipPosition="above" color="primary">calendar_today</mat-icon>
  </span>
</mat-form-field>

&nbsp;&nbsp;<mat-icon *ngIf="model.value && disablePickerReset != 'true'" matTooltip="Reset value" matTooltipPosition="above" color="primary" (click)="resetDatePickerValue()">remove_circle</mat-icon>

<div class="hard-error" *ngFor="let error of HardErrors">{{error.ErrorMessage}}</div>
<div class="soft-error" *ngFor="let error of SoftErrors">{{error.ErrorMessage}}</div>-->



<!--was places inside owl-date-time of Just Calendar or Timer but then (ngModelChange) for time picker wont work
    (yearSelected)="chosenYearHandler($event)"
    (monthSelected)="chosenMonthHandler($event)"
    (daySelected)="chosenDayHandler($event, dt1)"-->
