<div>
  <button class="form-execute-buttons" (click)="save()" type="button" color="primary" mat-raised-button matTooltip="Generate Report" matTooltipPosition="above">Generate Report</button>

  <div [hidden]="appService.isLoading | async">
    <span>ECLS Runs:</span>
    <br />
    <br />
    <form autocomplete="off" [formGroup]="form">
      <div class="control-item">
        <label for="StartTime">Start time:</label>
        <br />
        <elso-datetime-picker [model]="form.get('StartTime')" id="StartTime"></elso-datetime-picker>
      </div>
      <br />
      <div class="control-item">
        <label for="EndTime">End time:</label>
        <br />
        <elso-datetime-picker [model]="form.get('EndTime')" id="EndTime"></elso-datetime-picker>
      </div>
    </form>
    <br />
    <label>Search: &nbsp;</label>
    <input type="text" name="query" [(ngModel)]="query" placeholder="Search by uniqueid" />
    <br />
    <mat-checkbox id="AllUniqueIds" (change)="checkAllOnChange($event)">Check All</mat-checkbox>
    <br />
    <div class="elso-grid">
      <div class="elso-grid-row" *ngFor="let run of (allRuns | elsoGridFilter:query |
                         slice: paginatorService.lowValue : paginatorService.highValue)">
        <div>
          <mat-checkbox id="UniqueId_{{run.UniqueId}}" [(ngModel)]="run.Checked" (change)="checkboxOnChange(run.UniqueId,$event)"></mat-checkbox>
        </div>
        <div>
          <span class="elso-grid-label">Unique id: </span>
          <span>{{run.UniqueId}}</span>
        </div>
        <div>
          <span class="elso-grid-label">Run no: </span>
          <span>{{run.RunNo}}</span>
        </div>
        <div>
          <span class="elso-grid-label">Birthdate: </span>
          <span>{{run.Birthdate | dateFormat}}</span>
        </div>
        <div>
          <span class="elso-grid-label">Age: </span>
          <span>{{appService.getFormatedStringFromDays(run.Age)}}</span>
        </div>
        <div>
          <span class="elso-grid-label">Date On: </span>
          <span>{{run.TimeOn | dateFormat}}</span>
        </div>
        <div>
          <span class="elso-grid-label">Date off: </span>
          <span>{{run.TimeOff | dateFormat}}</span>
        </div>
        <div>
          <span class="elso-grid-label">D/C Alive:: </span>
          <span>{{getEnumName(run.DischargedAlive, enumService.enumDischargedAlive)}}</span>
        </div>
        <div>
          <span class="elso-grid-label">Submitted:</span>
          <span>{{run.submitedDate ? 'Yes' : 'No'}}</span>
        </div>
        <div>
          <span>Support type:</span>
          <span>{{appService.getEnumName(run.SupportType, this.appService.enumSupportTypes)}}</span>
        </div>
      </div>
    </div>
    <br />
    <br />
    <mat-paginator *ngIf="allRuns" [length]="allRuns.length" pageSize=5
                   (page)="paginatorService.getPaginatorData($event)" [pageSizeOptions]="[5, 20, 30]"
                   showFirstLastButtons="false">
    </mat-paginator>
  </div>
</div>
