import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../classes/user';
import { DataService } from '../../services/data.service'
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-login-secret',
  templateUrl: './loginSecret.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginSecretComponent implements OnInit {

  constructor(private dataService: DataService, private toastr: ToastrService, private router: Router, public appService: AppService) { }

  systemVersion: any;

  ngOnInit(): void {
    this.systemVersion = false;
    this.resetTokens();

    this.dataService.getall('Settings/GetSystemVersion').subscribe(
      (res: any) => {
        this.systemVersion = res.data;
        this.dataService.get('Users/CheckForELSOLogin', null, null).subscribe(
          (res: any) => {
            //console.log(res.data);
            if (res.tokenValid == true) {
              localStorage.setItem('token', res.data);
              //should somehow login user with existing token
              this.dataService.get('Users/GetUserProfile', null, null).subscribe(
                (res: any) => {
                  // console.log(res);
                  //need to set datetime format for user here
                  localStorage.setItem('unitSystem', res.UnitSystem);
                  localStorage.setItem('cultureSettings', res.DateTimeFormat);
                  localStorage.setItem('cultureOptionSelected', res.CultureOptionSelected);
                  localStorage.setItem('centerName', res.Name);
                  localStorage.setItem('centerId', res.CenterId);
                  localStorage.setItem('userName', res.UserName);

                  this.appService.setUserProfile();
                  /////

                  this.dataService.setEnumsAndCenter();
                  //console.log(this.appService.UserProfile);

                  this.router.navigateByUrl('/home')

                  if (this.appService.isUserInRole('Host User') == true || this.appService.isUserInRole('Host User - Registry Only') == true || this.appService.isUserInRole('Multi Center User') == true) {
                    //get centers for user, show dd list
                    this.dataService.getall('Users/GetCentersForUser').subscribe(
                      (res: any) => {
                        //this.centers = res.data;
                        //this.showCentersList = true;
                        this.appService.userCenters = res.data;
                        this.router.navigateByUrl('/home')

                      },
                      err => {
                        console.log(err);
                      }
                    );
                  } else {
                    this.router.navigateByUrl('/home')
                  }
                },
                err => {
                  console.log(err);
                }
              );
            }
          },
          err => {
            console.log(err);
          }
        );
      },
      err => {
        console.log(err);
      }
    );
  
  }

  loginUserModel = new User('', '', 0, '');

  showTfaOptions = false;
  tfaCodeSent = false;
  public submitForm() {
    if (this.showTfaOptions == true) {
      //send tfa code
      this.dataService.create('Users/RequestTFAcode', this.loginUserModel).subscribe(
        (res: any) => {
          this.showTfaOptions = false;
          this.tfaCodeSent = true;

          //this.toastr.success(res.message);
        },
        err => {
          console.log(err);
          this.toastr.error(err.error.message);
        }
      );
    } else if (this.tfaCodeSent == true) {
      //validate code and login user
      this.dataService.create('Users/VerifyTFAcode', this.loginUserModel).subscribe(
        (res: any) => {
          if (res.codeConfirmed == true) {
            this.showTfaOptions = false;
            this.tfaCodeSent = false;

            this.login();
          } else {
            this.toastr.error(res.message);
          }
        },
        err => {
          this.toastr.error(err.error.message);
        }
      );
    } else {
      //login user
      this.login();
    }
  }

  public resetForm() {
    this.showTfaOptions = false;
    this.tfaCodeSent = false;

    this.loginUserModel = new User('', '', 0, '');
  }
  public login() {
   // console.log(this.loginUserModel);
    this.dataService.login(this.loginUserModel).subscribe(
      (res: any) => {
       // console.log(res);
        if (res.tfaNotSelected == true) {
          //show tfa option selection
          this.showTfaOptions = true;
          return
        }
        if (res.tfaCodeSent == true) {
          //show tfa code input field
          this.tfaCodeSent = true;
          //console.log(this.loginUserModel);
          return;
        }

        //before token set, should check if some TFA message or error message is returned
        localStorage.setItem('token', res.data);
        this.toastr.success('Welcome to ELSO Registry', 'Authentication success.');

        var tokenHeader = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('token') });
        //var tokenHeader = new Headers({ 'Authorization': 'Bearer ' + localStorage.getItem('token') });
        this.dataService.get('Users/GetUserProfile', null, null).subscribe(
          (res: any) => {
            // console.log(res);
            //need to set datetime format for user here
            localStorage.setItem('unitSystem', res.UnitSystem);
            localStorage.setItem('cultureSettings', res.DateTimeFormat);
            localStorage.setItem('cultureOptionSelected', res.CultureOptionSelected);
            localStorage.setItem('centerName', res.Name);
            localStorage.setItem('centerId', res.CenterId);
            localStorage.setItem('userName', res.UserName);

            this.appService.setUserProfile();
            /////

            this.dataService.setEnumsAndCenter();
            //console.log(this.appService.UserProfile);

            this.router.navigateByUrl('/home')

            if (this.appService.isUserInRole('Host User') == true || this.appService.isUserInRole('Host User - Registry Only') == true || this.appService.isUserInRole('Multi Center User') == true) {
              //get centers for user, show dd list
              this.dataService.getall('Users/GetCentersForUser').subscribe(
                (res: any) => {
                  //this.centers = res.data;
                  //this.showCentersList = true;
                  this.appService.userCenters = res.data;
                  this.router.navigateByUrl('/home')

                },
                err => {
                  console.log(err);
                }
              );
            } else {
              this.router.navigateByUrl('/home')
            }
          },
          err => {
            console.log(err);
          }
        );
        //this.router.navigateByUrl('/home')
      },
      err => {
        console.log(err);
        if (err.status == 400)
          this.toastr.error('Authentication failed.', err.error.message);
        else
          this.toastr.error('Unexpected error.');
      }
    )
    //return this.http.post()
  }

  resetTokens() {
    localStorage.removeItem('cultureSettings');
    localStorage.removeItem('unitSystem');
    localStorage.removeItem('cultureOptionSelected');
    localStorage.removeItem('centerName');
    localStorage.removeItem('centerId');
    localStorage.removeItem('token');
  }

}
