<div [hidden]="appService.isLoading | async">
  <fieldset>
    <legend>Device scan</legend>
    <label>Search: &nbsp;</label>
    <input type="text" name="query" [(ngModel)]="query" placeholder="Search by uniqueid" />
    <br />
    <br />
    <div class="elso-grid">
      <div class="elso-grid-row" *ngFor="let patient of (patientsWithScannedDevices | elsoGridFilter:query |
                         slice: paginatorService.lowValue : paginatorService.highValue)">
        <div>
          <a (click)="displayPatientDevices(patient)">
            <mat-icon class="mat-icon" matTooltip="Edit" matTooltipPosition="above" color="primary">edit</mat-icon>
          </a>
          <a (click)="deleteScannedDevicesForPatient(patient)">
            <mat-icon class="mat-icon" matTooltip="Delete" matTooltipPosition="above" color="primary">delete</mat-icon>
          </a>
        </div>
        <div>
          <span class="elso-grid-label">Unique id: </span>
          <span>{{patient.PatientUniqueId}}</span>
        </div>
        <div>
          <span class="elso-grid-label">Birthdate: </span>
          <span>{{patient.BirthDate | dateFormat}}</span>
        </div>
        <div class="control-item">
          <span class="elso-grid-label">Sex: {{appService.getEnumName(patient.Sex, appService.enumSex)}}</span>
        </div>
        <div>
          <span class="elso-grid-label">Time on: </span>
          <span>{{patient.TimeOn | dateFormat}}</span>
        </div>
        <div>
          <span class="elso-grid-label">Time off: </span>
          <span>{{patient.TimeOff | dateFormat}}</span>
        </div>
      </div>
    </div>
    <br />
    <br />
    <mat-paginator *ngIf="patientsWithScannedDevices" [length]="patientsWithScannedDevices.length" pageSize=5
                   (page)="paginatorService.getPaginatorData($event)" [pageSizeOptions]="[5, 20, 30]"
                   showFirstLastButtons="false">
    </mat-paginator>
  </fieldset>
  <button [routerLink]="['/scandevice']" class="form-execute-buttons" type="button" color="primary" mat-raised-button>Back</button>
</div>


