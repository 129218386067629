import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { AppService } from '../../../services/app.service';
import { DataService } from '../../../services/data.service';
import { DateTimeFormatService } from '../../../services/datetime-format.service';
import { EnumService } from '../../../services/enum.service';
import { PaginatorService } from '../../../services/paginator.service';

@Component({
  templateUrl: './customPatientReport.component.html',
})

export class CustomPatientReportComponent {

  constructor(private dataService: DataService, public appService: AppService, private _dateTimeService: DateTimeFormatService,
    private sanitizer: DomSanitizer, public paginatorService: PaginatorService, public enumService: EnumService, private fb: FormBuilder) { }

  public query: string;
  public allRuns: any;
  public reportData: any[] = [];
  public form!: FormGroup;

  ngOnInit() {
    this.form = this.fb.group({
      StartTime: new FormControl(null),
      EndTime: new FormControl(null)
    });

    this.dataService.getall('runs/GetRunSummariesWithIncomplete').subscribe(
      (res: any) => {
        this.allRuns = res.data;        
      },
      err => {
        console.log(err);
      }
    )
  }

  checkboxOnChange(UniqueId, change) {
    if (change.checked) {
      this.reportData.push({ UniqueId: UniqueId, Checked: true });

      for (var i = 0; i < this.allRuns.length - 1; i++) {
        if (this.allRuns[i].UniqueId == UniqueId) {
          this.allRuns[i].Checked = true;
        }
      }

    } else {
      this.appService.lodashRemoveBy(this.reportData, 'UniqueId', UniqueId)
      for (var i = 0; i < this.allRuns.length - 1; i++) {
        if (this.allRuns[i].UniqueId == UniqueId) {
          this.allRuns[i].Checked = false;
        }
      }
    }

    console.log(change.checked);
    console.log(this.reportData);
  };

  checkAllOnChange(change) {

    if (change.checked) {
    this.reportData = _.toArray(_.mapValues(this.allRuns, function (item) { return { UniqueId: item.UniqueId, Checked: true } }));

      this.allRuns.map((obj) => {
        obj.Checked = true;
        return obj;
      })

    } else {
      this.reportData = [];

      this.allRuns.map((obj) => {
        obj.Checked = false;
        return obj;
      })
    }
  };


  save() {
    const uniqueIds = this.reportData.map((x) => x.UniqueId);

    var obj = {
      StartTime: this.form.controls['StartTime'].value,
      EndTime: this.form.controls['EndTime'].value,
      UniqueIdList: uniqueIds,
    }

    this.dataService.getRunDataDump('PatientReport/ExportToPDFStoredProcedures', obj).subscribe(
      (res: File) => {

        var date = new Date();
        var a = document.createElement("a");
        a.href = URL.createObjectURL(res);
        a.download = 'CustomPatientReport-' + String(date.getFullYear()) + '-' + String(date.getMonth() + 1) + '-' + String(date.getDate()) + '.pdf';
        a.click();

        },
        err => {
          console.log(err);
        }
      );

  }

  getEnumName(objValue: any, enumList: any[], valueProperty?: string, namePropertyString?: string) {
    if (objValue != null) {
      if (!valueProperty && !namePropertyString) {
        return this.appService.lodashFindBy(enumList, 'value', objValue).name;
      } else {
        return this.appService.lodashFindBy(enumList, valueProperty, objValue)[namePropertyString];
      }
    }
  }


}
