<div [hidden]="appService.isLoading | async">
  <div *ngIf="appService.Center && form">
    <form [formGroup]="form">
      <fieldset>
        <legend>
          Center Specific Devices
        </legend>
        <mat-accordion id="MembraneLungDevices">
          <mat-expansion-panel [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Membrane Lungs
              </mat-panel-title>
            </mat-expansion-panel-header>
            <br />
            <mat-checkbox formControlName="MembraneLungCenterSpecific" (change)="updateOnlyShowOption(1)">Only Show My Center Specific Equipment</mat-checkbox>
            <br />
            <div class="control-item">
              <label for="Manufacturer">Manufacturers:</label>
              <br /><br />
              <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
                <mat-select hideSingleSelectionIndicator name="Manufacturer" id="Manufacturer" placeholder="Please select" formControlName="ManufacturerMLId" (selectionChange)="updateDeviceList(1)">
                  <mat-option>Please select</mat-option>
                  <mat-option *ngFor="let manufacturer of enumService.DeviceEnums.enumMembraneLungsManufacturers" [value]="manufacturer.ManufacturerId">
                    {{manufacturer.Name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button (click)="addDevices(1, 'MembraneLungDevices')" class="form-execute-buttons" type="button" color="primary" mat-raised-button matTooltip="Add device" matTooltipPosition="above">Add devices</button>
            </div>

            <br />
            <div style="display: inline-block; width: 30%; margin-left: 10px;">
              <h3>Devices:</h3>
              <div style="overflow-y: auto; height: 20em; background-color: gainsboro; padding-left: 5px; ">
                <ng-container *ngFor="let device of deviceLookup[0]">
                  <mat-checkbox *ngIf="!appService.lodashFindBy(specificMLdevices, 'DeviceId', device.MembraneLungId) && device.isActive != false" style="width: 100%;" (change)="addRemoveDevice(1, device)">{{device.Name}}</mat-checkbox>
                </ng-container>
              </div>
            </div>
            <div style="display: inline-block; width: 30%; margin-left: 10px;">
              <h3>Selected Devices:</h3>
              <div style="overflow-y: auto; height: 20em; background-color: gainsboro; padding-left: 5px; ">
                <div style="width: 100%; display:block;" *ngFor="let device of specificMLdevices">
                  {{getDeviceName(device)}} <span style="position: relative; top: 5px;"> <mat-icon class="mat-icon" matTooltip="Remove" matTooltipPosition="above" color="primary" (click)="removeDeviceFromList(device, 'MembraneLungDevices')">close</mat-icon></span>
                </div>
              </div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>

        <br />
        <mat-accordion id="BloodPumpDevices">
          <mat-expansion-panel [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Blood Pumps
              </mat-panel-title>
            </mat-expansion-panel-header>
            <br />
            <mat-checkbox formControlName="BloodPumpCenterSpecific" (change)="updateOnlyShowOption(2)">Only Show My Center Specific Equipment</mat-checkbox>
            <br />
            <div class="control-item">
              <label for="Manufacturer">Manufacturers:</label>
              <br /><br />
              <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
                <mat-select hideSingleSelectionIndicator name="Manufacturer" id="Manufacturer" placeholder="Please select" formControlName="ManufacturerBPId" (selectionChange)="updateDeviceList(2)">
                  <mat-option>Please select</mat-option>
                  <mat-option *ngFor="let manufacturer of enumService.DeviceEnums.enumBloodPumpsManufacturers" [value]="manufacturer.ManufacturerId">
                    {{manufacturer.Name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button (click)="addDevices(2, 'BloodPumpDevices')" class="form-execute-buttons" type="button" color="primary" mat-raised-button matTooltip="Add device" matTooltipPosition="above">Add devices</button>
            </div>

            <br />
            <div style="display: inline-block; width: 30%; margin-left: 10px;">
              <h3>Devices:</h3>
              <div style="overflow-y: auto; height: 20em; background-color: gainsboro; padding-left: 5px; ">
                <ng-container *ngFor="let device of deviceLookup[1]">
                  <mat-checkbox *ngIf="!appService.lodashFindBy(specificBPdevices, 'DeviceId', device.PumpId) && device.isActive != false" style="width: 100%;" (change)="addRemoveDevice(2, device)">{{device.Name}}</mat-checkbox>
                </ng-container>
              </div>
            </div>
            <div style="display: inline-block; width: 30%; margin-left: 10px;">
              <h3>Selected Devices:</h3>
              <div style="overflow-y: auto; height: 20em; background-color: gainsboro; padding-left: 5px; ">
                <div style="width: 100%; display:block;" *ngFor="let device of specificBPdevices">
                  {{getDeviceName(device)}} <span style="position: relative; top: 5px;"> <mat-icon class="mat-icon" matTooltip="Remove" matTooltipPosition="above" color="primary" (click)="removeDeviceFromList(device, 'BloodPumpDevices')">close</mat-icon></span>
                </div>
              </div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>

        <br />
        <mat-accordion id="HemofilterDevices">
          <mat-expansion-panel [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Hemofilters
              </mat-panel-title>
            </mat-expansion-panel-header>
            <br />
            <mat-checkbox formControlName="HemofilterCenterSpecific" (change)="updateOnlyShowOption(4)">Only Show My Center Specific Equipment</mat-checkbox>
            <br />
            <div class="control-item">
              <label for="Manufacturer">Manufacturers:</label>
              <br /><br />
              <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
                <mat-select hideSingleSelectionIndicator name="Manufacturer" id="Manufacturer" placeholder="Please select" formControlName="ManufacturerHId" (selectionChange)="updateDeviceList(4)">
                  <mat-option>Please select</mat-option>
                  <mat-option *ngFor="let manufacturer of enumService.DeviceEnums.enumHemofiltersManufacturers" [value]="manufacturer.ManufacturerId">
                    {{manufacturer.Name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button (click)="addDevices(4, 'HemofilterDevices')" class="form-execute-buttons" type="button" color="primary" mat-raised-button matTooltip="Add device" matTooltipPosition="above">Add devices</button>
            </div>

            <br />
            <div style="display: inline-block; width: 30%; margin-left: 10px;">
              <h3>Devices:</h3>
              <div style="overflow-y: auto; height: 20em; background-color: gainsboro; padding-left: 5px; ">
                <ng-container *ngFor="let device of deviceLookup[2]">
                  <mat-checkbox *ngIf="!appService.lodashFindBy(specificHdevices, 'DeviceId', device.HemofilterId) && device.isActive != false" style="width: 100%;" (change)="addRemoveDevice(4, device)">{{device.Name}}</mat-checkbox>
                </ng-container>
              </div>
            </div>
            <div style="display: inline-block; width: 30%; margin-left: 10px;">
              <h3>Selected Devices:</h3>
              <div style="overflow-y: auto; height: 20em; background-color: gainsboro; padding-left: 5px; ">
                <div style="width: 100%; display:block;" *ngFor="let device of specificHdevices">
                  {{getDeviceName(device)}} <span style="position: relative; top: 5px;"> <mat-icon class="mat-icon" matTooltip="Remove" matTooltipPosition="above" color="primary" (click)="removeDeviceFromList(device, 'HemofilterDevices')">close</mat-icon></span>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <br />
        <mat-accordion id="TemperatureRegulationDevices">
          <mat-expansion-panel [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Temperature Regulations
              </mat-panel-title>
            </mat-expansion-panel-header>
            <br />
            <mat-checkbox formControlName="TemperatureRegulationCenterSpecific" (change)="updateOnlyShowOption(5)">Only Show My Center Specific Equipment</mat-checkbox>
            <br />
            <div class="control-item">
              <label for="Manufacturer">Manufacturers:</label>
              <br /><br />
              <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
                <mat-select hideSingleSelectionIndicator name="Manufacturer" id="Manufacturer" placeholder="Please select" formControlName="ManufacturerTRId" (selectionChange)="updateDeviceList(5)">
                  <mat-option>Please select</mat-option>
                  <mat-option *ngFor="let manufacturer of enumService.DeviceEnums.enumTemperatureRegulationsManufacturers" [value]="manufacturer.ManufacturerId">
                    {{manufacturer.Name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button (click)="addDevices(5, 'TemperatureRegulationDevices')" class="form-execute-buttons" type="button" color="primary" mat-raised-button matTooltip="Add device" matTooltipPosition="above">Add devices</button>
            </div>

            <br />
            <div style="display: inline-block; width: 30%; margin-left: 10px;">
              <h3>Devices:</h3>
              <div style="overflow-y: auto; height: 20em; background-color: gainsboro; padding-left: 5px; ">
                <ng-container *ngFor="let device of deviceLookup[3]">
                  <mat-checkbox *ngIf="!appService.lodashFindBy(specificTRdevices, 'DeviceId', device.TemperatureRegulationId) && device.isActive != false" style="width: 100%;" (change)="addRemoveDevice(5, device)">{{device.Name}}</mat-checkbox>
                </ng-container>
              </div>
            </div>
            <div style="display: inline-block; width: 30%; margin-left: 10px;">
              <h3>Selected Devices:</h3>
              <div style="overflow-y: auto; height: 20em; background-color: gainsboro; padding-left: 5px; ">
                <div style="width: 100%; display:block;" *ngFor="let device of specificTRdevices">
                  {{getDeviceName(device)}} <span style="position: relative; top: 5px;"> <mat-icon class="mat-icon" matTooltip="Remove" matTooltipPosition="above" color="primary" (click)="removeDeviceFromList(device, 'TemperatureRegulationDevices')">close</mat-icon></span>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <br />
        <mat-accordion id="CannulaDevices">
          <mat-expansion-panel [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Cannulas
              </mat-panel-title>
            </mat-expansion-panel-header>
            <br />
            <mat-checkbox formControlName="CannulaCenterSpecific" (change)="updateOnlyShowOption(6)">Only Show My Center Specific Equipment</mat-checkbox>
            <br />
            <div class="control-item">
              <label for="Manufacturer">Manufacturers:</label>
              <br /><br />
              <mat-form-field class="select-form-field" floatLabel="always" appearance="fill">
                <mat-select hideSingleSelectionIndicator name="Manufacturer" id="Manufacturer" placeholder="Please select" formControlName="ManufacturerCId" (selectionChange)="updateDeviceList(6)">
                  <mat-option>Please select</mat-option>
                  <mat-option *ngFor="let manufacturer of enumService.DeviceEnums.enumCannulasManufacturers" [value]="manufacturer.ManufacturerId">
                    {{manufacturer.Name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button (click)="addDevices(6, 'CannulaDevices')" class="form-execute-buttons" type="button" color="primary" mat-raised-button matTooltip="Add device" matTooltipPosition="above">Add devices</button>
            </div>

            <br />
            <div style="display: inline-block; width: 30%; margin-left: 10px;">
              <h3>Devices:</h3>
              <div style="overflow-y: auto; height: 20em; background-color: gainsboro; padding-left: 5px; ">
                <ng-container *ngFor="let device of deviceLookup[4]">
                  <mat-checkbox *ngIf="!appService.lodashFindBy(specificCdevices, 'DeviceId', device.CannulaId) && device.Visible != false" style="width: 100%;" (change)="addRemoveDevice(6, device)">{{device.Description}}</mat-checkbox>
                </ng-container>
              </div>
            </div>
            <div style="display: inline-block; width: 30%; margin-left: 10px;">
              <h3>Selected Devices:</h3>
              <div style="overflow-y: auto; height: 20em; background-color: gainsboro; padding-left: 5px; ">
                <div style="width: 100%; display:block;" *ngFor="let device of specificCdevices">
                  {{getDeviceName(device)}} <span style="position: relative; top: 5px;"> <mat-icon class="mat-icon" matTooltip="Remove" matTooltipPosition="above" color="primary" (click)="removeDeviceFromList(device, 'CannulaDevices')">close</mat-icon></span>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

      </fieldset>

      <button [routerLink]="['/home']" type="button" class="form-execute-buttons" color="primary" mat-raised-button>Back</button>
    </form>
  </div>
  
</div>
