<div>

  <button (click)="convertHTMLtoPDF()">
    test
  </button>


  <div id="pdfContentHolder" style="margin:auto; width: 720px; height: 800px;">

    <!--<link href="reportsCSS/report.css" rel="stylesheet" type="text/css" />--><!--used to load styles from service-->


    <!--<link href="report.css" rel="stylesheet" type="text/css" />-->

    <header style="display:none; margin-top:24px;">
      <table width="100%">
        <tr>
          <td style="text-align: right; font-size: 11px; color: #555">Length of stay - {{getMonthYearHeader()}}</td>
        </tr>
      </table>
    </header>
    <table width="100%">
      <tr>
        <td><h1>ECLS Registry Report</h1><br />Length of stay<br />{{getMonthYearHeader()}}<br /><span>Report data through 2023</span><!--<br /><span data-ng-if="isJulyReport();">For July reports, the current year is reported as a partial year only</span>--></td>
        <td style="text-align:center;"><img id="headerLogo" [src]="transformBase64Image('logo')" style="width: 150px; height:100px;" src="" /></td>
        <td style="text-align:right">
          <p>www.elso.org</p>
        </td>
      </tr>
    </table>


    <div style="margin-top: 10px;" class="blackHeaderHolder"><h1 class="pdf_outlines">Adult Respiratory (18 years and over)</h1></div>
    <br />
    <h2 style="font-size: 13.5px;" class="pdf_outlines">Adult Respiratory Runs by Year</h2><br>
    <report-runs-by-year [reportData]="this.reportData?.LoSReportAdRespRunsByYearResult"></report-runs-by-year>

    <div style="page-break-after: always;"></div>

    <div style="margin-top: 10px;" class="blackHeaderHolder"><h1 class="pdf_outlines">Pediatric Respiratory (&gt;28 days and &lt;18 years)</h1></div>
    <br />
    <h2 style="font-size: 13.5px;" class="pdf_outlines">Pediatric Respiratory Runs by Year</h2><br>
    <report-runs-by-year [reportData]="this.reportData?.LoSReportPedRespRunsByYearResult"></report-runs-by-year>

    <div style="page-break-after: always;"></div>

    <div style="margin-top: 10px;" class="blackHeaderHolder"><h1 class="pdf_outlines">Neonatal Respiratory (0-28 days)</h1></div>
    <br />
    <h2 style="font-size: 13.5px;" class="pdf_outlines">Neonatal Respiratory Runs by Year</h2><br>
    <report-runs-by-year [reportData]="this.reportData?.LoSReportNeoRespRunsByYearResult"></report-runs-by-year>

    <div style="page-break-after: always;"></div>

    <div style="margin-top: 10px;" class="blackHeaderHolder"><h1 class="pdf_outlines">Adult Cardiac (18 years and over)</h1></div>
    <br />
    <h2 style="font-size: 13.5px;" class="pdf_outlines">Adult Cardiac Runs by Year</h2><br>
    <report-runs-by-year [reportData]="this.reportData?.LoSReportAdCardRunsByYearResult"></report-runs-by-year>

    <div style="page-break-after: always;"></div>

    <div style="margin-top: 10px;" class="blackHeaderHolder"><h1 class="pdf_outlines">Pediatric Cardiac (&gt;28 days and &lt;18 years)</h1></div>
    <br />
    <h2 style="font-size: 13.5px;" class="pdf_outlines">Pediatric Cardiac Runs by Year</h2><br>
    <report-runs-by-year [reportData]="this.reportData?.LoSReportPedCardRunsByYearResult"></report-runs-by-year>

    <div style="page-break-after: always;"></div>

    <div style="margin-top: 10px;" class="blackHeaderHolder"><h1 class="pdf_outlines">Neonatal Cardiac (0-28 days)</h1></div>
    <br />
    <h2 style="font-size: 13.5px;" class="pdf_outlines">Neonatal Cardiac Runs by Year</h2><br>
    <report-runs-by-year [reportData]="this.reportData?.LoSReportNeoCardRunsByYearResult"></report-runs-by-year>

    <div style="page-break-after: always;"></div>

    <div style="margin-top: 10px;" class="blackHeaderHolder"><h1 class="pdf_outlines">Adult ECPR (18 years and over)</h1></div>
    <br />
    <h2 style="font-size: 13.5px;" class="pdf_outlines">Adult ECPR Runs by Year</h2><br>
    <report-runs-by-year [reportData]="this.reportData?.LoSReportAdECPRRunsByYearResult"></report-runs-by-year>

    <div style="page-break-after: always;"></div>

    <div style="margin-top: 10px;" class="blackHeaderHolder"><h1 class="pdf_outlines">Pediatric ECPR (&gt;28 days and &lt;18 years)</h1></div>
    <br />
    <h2 style="font-size: 13.5px;" class="pdf_outlines">Pediatric ECPR Runs by Year</h2><br>
    <report-runs-by-year [reportData]="this.reportData?.LoSReportPedECPRRunsByYearResult"></report-runs-by-year>

    <div style="page-break-after: always;"></div>

    <div style="margin-top: 10px;" class="blackHeaderHolder"><h1 class="pdf_outlines">Neonatal ECPR (0-28 days)</h1></div>
    <br />
    <h2 style="font-size: 13.5px;" class="pdf_outlines">Neonatal ECPR Runs by Year</h2><br>
    <report-runs-by-year [reportData]="this.reportData?.LoSReportNeoECPRRunsByYearResult"></report-runs-by-year>

    <footer style="display:none">
      <table width="100%" class="pdfTables">
        <thead>
          <tr>
            <th style="border:none;"></th>
            <th style="border:none;"></th>
          </tr>
        </thead>
        <tbody>
          <tr style="border-top: 1px solid black; border-bottom: 1px solid black;">
            <td style="text-align: left; font-size: 12px; font-weight:initial; color: #555">{{getDayMonthYearFooter()}}</td>
            <td style="text-align: right; font-size: 12px; font-weight: initial; color: #555 ">(c) {{getYearFooter()}} Extracorporeal Life Support Organization</td>
          </tr>
        </tbody>
      </table>
      <!--<p style="text-align: right; padding-right: 9px; font-size: 12px; font-weight: initial; color: #555 ">&nbsp;&nbsp;&nbsp;Page <pagenum></pagenum>--> <!--of <totpages></totpages>--><!--</p>-->
    </footer>
  </div>
</div>
