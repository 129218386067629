import { Component, Input } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { DateTimeFormatService } from '../../../services/datetime-format.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { EnumService } from '../../../services/enum.service';
import { DeviceEnums } from '../../../classes/deviceEnums';

@Component({
  templateUrl: './centerSpecificDevices.component.html',
})

export class CenterSpecificDevicesComponent {

  constructor(private dataService: DataService, public appService: AppService, public enumService: EnumService, private _dateTimeService: DateTimeFormatService, private fb: FormBuilder, private router: Router) {
    this.deviceLookup = [];

    this.mlDevicesToAdd = [];
    this.bpDevicesToAdd = [];
    this.hDevicesToAdd = [];
    this.trDevicesToAdd = [];
    this.cDevicesToAdd = [];

    if (!this.enumService.DeviceEnums) {
      this.enumService.DeviceEnums = new DeviceEnums();

      this.dataService.getall('Enums/GetEquipmentDevicesAndManufacturers').subscribe(
        (res: any) => {
          //console.log(res);
          this.enumService.DeviceEnums.enumAllMembraneLungs = res.data.AllMembraneLungDevicesList;
          this.enumService.DeviceEnums.enumMembraneLungs = res.data.MembraneLungDevicesList;
          this.enumService.DeviceEnums.enumMembraneLungsManufacturers = res.data.MembraneLungManufacturersList;
          this.enumService.DeviceEnums.enumAllBloodPumps = res.data.AllPumpDevicesList;
          this.enumService.DeviceEnums.enumBloodPumps = res.data.PumpDevicesList;
          this.enumService.DeviceEnums.enumBloodPumpsManufacturers = res.data.PumpManufacturersList;
          this.enumService.DeviceEnums.enumAllHemofilters = res.data.AllHemofilterDevicesList;
          this.enumService.DeviceEnums.enumHemofilters = res.data.HemofilterDevicesList;
          this.enumService.DeviceEnums.enumHemofiltersManufacturers = res.data.HemofilterManufacturersList;
          this.enumService.DeviceEnums.enumAllTemperatureRegulations = res.data.AllTemperatureRegulationDevicesList;
          this.enumService.DeviceEnums.enumTemperatureRegulations = res.data.TemperatureRegulationDevicesList;
          this.enumService.DeviceEnums.enumTemperatureRegulationsManufacturers = res.data.TemperatureRegulationManufacturersList;
          this.enumService.DeviceEnums.enumAllCannulas = res.data.AllCannulaDevicesList;
          this.enumService.DeviceEnums.enumCannulas = res.data.CannulaDevicesList;
          this.enumService.DeviceEnums.enumCannulasManufacturers = res.data.CannulaManufacturersList;
          this.enumService.DeviceEnums.membraneLungsCenterSpecific = res.data.CSMembraneLungDevicesList;
          this.enumService.DeviceEnums.bloodPumpsCenterSpecific = res.data.CSBloodPumpDevicesList;
          this.enumService.DeviceEnums.hemofiltersCenterSpecific = res.data.CSHemofilterDevicesList;
          this.enumService.DeviceEnums.temperatureRegulationsCenterSpecific = res.data.CSTemperatureRegulationDevicesList;
          this.enumService.DeviceEnums.cannulasCenterSpecific = res.data.CSCannulaDevicesList;
          console.log(res.data.CSCannulaDevicesList);

          //console.log(this.appService.cannulasCenterSpecific);
        },
        err => {
          console.log(err);
        }
      )
    } else {
      this.setDevicesList(null);
    }
  }

  refreshCSDevices() {
    this.dataService.getall('Equipment/GetCenterSpecificEquipment').subscribe(
      (res: any) => {
        //console.log(res);
        var devices = res.data;

        this.setDevicesList(devices);


      },
      err => {
        console.log(err);
      }
    )
  };


  setDevicesList(devices: any[]) {
    //this.specificMLdevices = this.appService.lodashFilterBy(devices, 'DeviceType', 1);
    //this.specificBPdevices = this.appService.lodashFilterBy(devices, 'DeviceType', 2);
    //this.specificHdevices = this.appService.lodashFilterBy(devices, 'DeviceType', 4);
    //this.specificTRdevices = this.appService.lodashFilterBy(devices, 'DeviceType', 5);
    //this.specificCdevices = this.appService.lodashFilterBy(devices, 'DeviceType', 6);
    
    if (devices) {
      this.enumService.DeviceEnums.membraneLungsCenterSpecific = this.appService.lodashFilterBy(devices, 'DeviceType', 1);
      this.enumService.DeviceEnums.bloodPumpsCenterSpecific = this.appService.lodashFilterBy(devices, 'DeviceType', 2);
      this.enumService.DeviceEnums.hemofiltersCenterSpecific = this.appService.lodashFilterBy(devices, 'DeviceType', 4);
      this.enumService.DeviceEnums.temperatureRegulationsCenterSpecific = this.appService.lodashFilterBy(devices, 'DeviceType', 5);
      this.enumService.DeviceEnums.cannulasCenterSpecific = this.appService.lodashFilterBy(devices, 'DeviceType', 6);
    }

    this.specificMLdevices = this.enumService.DeviceEnums.membraneLungsCenterSpecific;
    this.specificBPdevices = this.enumService.DeviceEnums.bloodPumpsCenterSpecific;
    this.specificHdevices = this.enumService.DeviceEnums.hemofiltersCenterSpecific;
    this.specificTRdevices = this.enumService.DeviceEnums.temperatureRegulationsCenterSpecific;
    this.specificCdevices = this.enumService.DeviceEnums.cannulasCenterSpecific;
  }

  public form!: FormGroup;
  deviceLookup = [];
  specificMLdevices = [];
  mlDevicesToAdd = [];
  bpDevicesToAdd = [];
  hDevicesToAdd = [];
  trDevicesToAdd = [];
  cDevicesToAdd = [];

  specificBPdevices = [];
  specificHdevices = [];
  specificTRdevices = [];
  specificCdevices = [];


  addRemoveDevice(deviceType: number, device: any) {
    var deviceIdToCheck = null;
    var deviceArray = [];

    switch (deviceType) {
      case 1:
        deviceIdToCheck = device.MembraneLungId;
        deviceArray = this.mlDevicesToAdd;
        break;
      case 2:
        deviceIdToCheck = device.PumpId;
        deviceArray = this.bpDevicesToAdd;
        break;
      case 4:
        deviceIdToCheck = device.HemofilterId;
        deviceArray = this.hDevicesToAdd;
        break;
      case 5:
        deviceIdToCheck = device.TemperatureRegulationId;
        deviceArray = this.trDevicesToAdd;
        break;
      case 6:
        deviceIdToCheck = device.CannulaId;
        deviceArray = this.cDevicesToAdd;
        break;
    }

    //console.log(device);

    if (!this.appService.lodashFindBy(deviceArray, 'DeviceId', deviceIdToCheck)) {
      deviceArray.push({
        DeviceId: deviceIdToCheck,
        DeviceType: deviceType
      });
    } else {
      this.appService.lodashRemoveBy(deviceArray, 'DeviceId', deviceIdToCheck);
    }

    switch (deviceType) {
      case 1:
        this.mlDevicesToAdd = deviceArray;
        break;
      case 2:
        this.bpDevicesToAdd = deviceArray;
        break;
      case 4:
        this.hDevicesToAdd = deviceArray;
        break;
      case 5:
        this.trDevicesToAdd = deviceArray;
        break;
      case 6:
        this.cDevicesToAdd = deviceArray;
        break;
    }

    //console.log(this.mlDevicesToAdd);
  }

  addDevices(deviceType: number, anchor: string) {

    var devicesToUpdate = [];

    switch (deviceType) {
      case 1:
        devicesToUpdate = this.mlDevicesToAdd;
        this.mlDevicesToAdd = [];
        break;
      case 2:
        devicesToUpdate = this.bpDevicesToAdd;
        this.mlDevicesToAdd = [];
        break;
      case 4:
        devicesToUpdate = this.hDevicesToAdd;
        this.hDevicesToAdd = [];
        break;
      case 5:
        devicesToUpdate = this.trDevicesToAdd;
        this.trDevicesToAdd = [];
        break;
      case 6:
        devicesToUpdate = this.cDevicesToAdd;
        this.cDevicesToAdd = [];
        break;
    }

    this.dataService.update('Equipment/UpdateCenterSpecificEquipment', devicesToUpdate).subscribe(
      (res: any) => {
        var devices = res.data;

        this.setDevicesList(devices);

        this.gotoAnchor(anchor);
      },
      err => {
        console.log(err);
      }
    );

  }

  updateOnlyShowOption(deviceTyepe: number) {
    var centerUpdateObj = {
      MembraneLungFavorite: this.form.controls['MembraneLungCenterSpecific'].value,
      PumpFavorite: this.form.controls['BloodPumpCenterSpecific'].value,
      HemofilterFavorite: this.form.controls['HemofilterCenterSpecific'].value,
      TemperatureRegulationFavorite: this.form.controls['TemperatureRegulationCenterSpecific'].value,
      CannulaFavorite: this.form.controls['CannulaCenterSpecific'].value
    };

    //console.log(centerUpdateObj);


    this.dataService.update('Equipment/UpdateCenterSpecificDisplayOption', centerUpdateObj).subscribe(
      (res: any) => {
        this.appService.Center = res.data;
      },
      err => {
        console.log(err);
      }
    );

  }

  getDeviceName(device: any) {
    if (this.enumService.DeviceEnums) {
      switch (device.DeviceType) {
        case 1:
          if (this.enumService.DeviceEnums.enumMembraneLungs.length) {
            return this.appService.lodashFindBy(this.enumService.DeviceEnums.enumMembraneLungs, 'MembraneLungId', device.DeviceId).Name;
          }
          break;
        case 2:
          if (this.enumService.DeviceEnums.enumBloodPumps.length) {
            return this.appService.lodashFindBy(this.enumService.DeviceEnums.enumBloodPumps, 'PumpId', device.DeviceId).Name;
          }
          break;
        case 4:
          if (this.enumService.DeviceEnums.enumHemofilters.length) {
            return this.appService.lodashFindBy(this.enumService.DeviceEnums.enumHemofilters, 'HemofilterId', device.DeviceId).Name;
          }
          break;
        case 5:
          if (this.enumService.DeviceEnums.enumTemperatureRegulations.length) {
            return this.appService.lodashFindBy(this.enumService.DeviceEnums.enumTemperatureRegulations, 'TemperatureRegulationId', device.DeviceId).Name;
          }
          break;
        case 6:
          if (this.enumService.DeviceEnums.enumCannulas.length) {
            return this.appService.lodashFindBy(this.enumService.DeviceEnums.enumCannulas, 'CannulaId', device.DeviceId).Description;
          }
          break;
      }

    }

  }
  updateDeviceList(deviceType: number) {
    switch (deviceType) {
      case 1:
        this.deviceLookup[0] = [];
        if (this.form.controls['ManufacturerMLId'].value != undefined) {
          this.deviceLookup[0] = this.appService.lodashFindBy(this.enumService.DeviceEnums.enumMembraneLungsManufacturers, 'ParentManufacturerId', this.form.controls['ManufacturerMLId'].value).MembraneLungs;
        }
        break;
      case 2:
        this.deviceLookup[1] = [];
        if (this.form.controls['ManufacturerBPId'].value != undefined) {
          this.deviceLookup[1] = this.appService.lodashFindBy(this.enumService.DeviceEnums.enumBloodPumpsManufacturers, 'ParentManufacturerId', this.form.controls['ManufacturerBPId'].value).Pumps;
        }
        break;
      //case 3://Not in use
      //  this.form.controls['HeatExchangerId'].setValue(null);
      //  this.DeviceLookup[2] = this.appService.lodashFindBy(this.enumService.DeviceEnums.HeatExchangersManufacturers, 'ParentManufacturerId', this.form.controls['ManufacturerId'].value).HeatExchangers;
      //  break;
      case 4:
        this.deviceLookup[2] = [];
        if (this.form.controls['ManufacturerHId'].value != undefined) {
          this.deviceLookup[2] = this.appService.lodashFindBy(this.enumService.DeviceEnums.enumHemofiltersManufacturers, 'ParentManufacturerId', this.form.controls['ManufacturerHId'].value).Hemofilters;
        }
        break;
      case 5:
        this.deviceLookup[3] = [];
        if (this.form.controls['ManufacturerTRId'].value != undefined) {
          this.deviceLookup[3] = this.appService.lodashFindBy(this.enumService.DeviceEnums.enumTemperatureRegulationsManufacturers, 'ParentManufacturerId', this.form.controls['ManufacturerTRId'].value).TemperatureRegulations;
        }
        break;
      case 6:
        this.deviceLookup[4] = [];
        if (this.form.controls['ManufacturerCId'].value != undefined) {
          this.deviceLookup[4] = this.appService.lodashFindBy(this.enumService.DeviceEnums.enumCannulasManufacturers, 'ParentManufacturerId', this.form.controls['ManufacturerCId'].value).Cannulas;
        }
        break;
    }
  }

  removeDeviceFromList(device: any, anchor: string) {
    this.dataService.update('Equipment/DeleteCenterSpecificEquipment', device).subscribe(
      (res: any) => {
        var devices = res.data;
        //console.log(devices);
        this.setDevicesList(devices);
        this.gotoAnchor(anchor);
      },
      err => {
        console.log(err);
      }
    );
  }

  gotoAnchor = function (scrollTo) {
    //setTimeout(function () { //menu toolbar not visible after updating section on mobile screen
    //  document.getElementById(scrollTo).scrollIntoView(true);
    //}, 100);
  };

  ngOnInit() {

    this.dataService.getall('Users/GetUserCenter').subscribe(
      (res: any) => {
        this.appService.Center = res.data;
        this.form = this.fb.group({
          ManufacturerMLId: new FormControl(null),
          MembraneLungCenterSpecific: new FormControl(this.appService.Center.MembraneLungFavorite),
          ManufacturerBPId: new FormControl(null),
          BloodPumpCenterSpecific: new FormControl(this.appService.Center.PumpFavorite),
          ManufacturerHId: new FormControl(null),
          HemofilterCenterSpecific: new FormControl(this.appService.Center.HemofilterFavorite),
          ManufacturerTRId: new FormControl(null),
          TemperatureRegulationCenterSpecific: new FormControl(this.appService.Center.TemperatureRegulationFavorite),
          ManufacturerCId: new FormControl(null),
          CannulaCenterSpecific: new FormControl(this.appService.Center.CannulaFavorite),
        });

        this.refreshCSDevices();
      },
      err => {
        console.log(err);
      }
    )

    //console.log(this.form.value);
    //updatedObject.MembraneLungFavorite = Center.MembraneLungFavorite;
    //updatedObject.PumpFavorite = Center.PumpFavorite;
    //updatedObject.HemofilterFavorite = Center.HemofilterFavorite;
    //updatedObject.TemperatureRegulationFavorite = Center.TemperatureRegulationFavorite;
    //updatedObject.CannulaFavorite = Center.CannulaFavorite;
  }
}
