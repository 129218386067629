<div [hidden]="appService.isLoading | async">
  <form [formGroup]="form">
    <fieldset>
      <legend>
        User profile
      </legend>
      <div class="control-item">
        <mat-form-field class="select-form-field">
          <mat-label>Date/time format:</mat-label>
          <mat-select hideSingleSelectionIndicator name="CultureOptionSelected" placeholder="Please select" formControlName="CultureOptionSelected">
            <mat-option>Please select</mat-option>
            <!--<mat-option [value]="-5">
        Please Select
      </mat-option>-->
            <mat-option *ngFor="let culture of appService.enumFormatOptions" [value]="culture.value">
              {{culture.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="hard-error" *ngIf="form.controls['CultureOptionSelected'].hasError('required')">This field is required!</div>
      </div>

      <div class="control-item">
        <mat-form-field class="select-form-field">
          <mat-label>Unit system:</mat-label>
          <mat-select hideSingleSelectionIndicator name="UnitSystem" placeholder="Please select" formControlName="UnitSystem">
            <mat-option>Please select</mat-option>
            <mat-option *ngFor="let unit of appService.enumUnitSystem" [value]="unit.value">
              {{unit.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="hard-error" *ngIf="form.controls['UnitSystem'].hasError('required')">This field is required!</div>
      </div>

      <!--<div class="control-item" *ngIf="showCentersList">
        <mat-form-field class="select-form-field">
          <mat-label>Center:</mat-label>
          <mat-select hideSingleSelectionIndicator name="Center" placeholder="Please select" formControlName="CenterSelected">
            <mat-option>Please select</mat-option>
            <mat-option *ngFor="let center of centers" [value]="center.CenterId">
              {{center.CenterNo}} - {{center.Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>-->
      <br />
      <div>
        <button class="form-execute-buttons" type="button" color="primary" (click)="save()" mat-raised-button [disabled]="form.invalid">Save</button>
        <button [routerLink]="['/home']" type="button" class="form-execute-buttons" color="primary" mat-raised-button>Cancel</button>
      </div>
    </fieldset>
  </form>
  <br />
  <form *ngIf="(appService.isUserInRole('Host User') || appService.isUserInRole('Host User - Registry Only'))" [formGroup]="formAdministration">
    <fieldset>
      <legend>System administration</legend>

      <div class="control-item">
        <mat-form-field class="select-form-field">
          <mat-label class="required-field">System is:</mat-label>
          <mat-select hideSingleSelectionIndicator name="IsOffline" placeholder="Please select" formControlName="IsOffline">
            <mat-option>Please select</mat-option>
            <mat-option *ngFor="let obj of enumService.enumSystemOnlineOffline" [value]="obj.value">
              {{obj.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="hard-error" *ngIf="formAdministration.controls['IsOffline'].hasError('required')">This field is required!</div>
      </div>
      <div class="control-item">
        <label>System message:</label>
        <input type="text" placeholder="System Message" matInput formControlName="SystemMessage">
      </div>
      <br />
      <div>
        <button class="form-execute-buttons" type="button" color="primary" (click)="updateAdministration()" mat-raised-button [disabled]="formAdministration.invalid">Save</button>
        <button [routerLink]="['/home']" type="button" class="form-execute-buttons" color="primary" mat-raised-button>Cancel</button>
      </div>
    </fieldset>
    </form>
</div>
