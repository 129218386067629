<!--<br />
<table width="100%" class="pdfTables">
  <thead>
    <tr>
      <th class="altHead"></th>
      <th class="altHead">Annual Runs</th>
      <th class="altHead">Cumulative Runs</th>
      <th class="altHead">Average Run Time</th>
      <th class="altHead">Longest Run Time</th>
      <th class="altHead">No. Survived</th>
      <th class="altHead">% Survived</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let nrrbY of reportData">
      <td>{{nrrbY.YearEcmo}}</td>
      <td class="alt">{{nrrbY.AnnRuns}}</td>
      <td class="alt">{{nrrbY.cumulativeRuns}}</td>
      <td class="alt">{{nrrbY.AvgRunTime}}</td>
      <td class="alt">{{nrrbY.LongRunTime}}</td>
      <td class="alt">{{nrrbY.NoSurvived}}</td>
      <td class="alt">{{nrrbY.PercSurv}}%</td>
    </tr>
  </tbody>
</table>-->
<br />
<table width="100%" class="pdfTables">
  <thead>
    <tr>
      <th class="altHead"></th>
      <th class="altHead">Annual Runs</th>
      <th class="altHead">Cumulative Runs</th>
      <th class="altHead" colspan="3" style="text-align: center;">
        <table width="100%">
          <tr>
            <th colspan="3" style="border: 0px; text-align: center;">
              Run Time (IQR)
            </th>
          </tr>
          <tr>
            <th style="border: 0px;">25%</th>
            <th style="border: 0px;">Median</th>
            <th style="border: 0px;">75%</th>
          </tr>
        </table>
      </th>
      <th class="altHead">Longest Run Time</th>

      <th class="altHead" colspan="2" style="text-align: center;">
        <table width="100%">
          <tr>
            <th colspan="2" style="border: 0px; text-align: center;">
              Survived to DC
              or Transfer
            </th>
          </tr>
          <tr>
            <th style="border: 0px;">No. Survived</th>
            <th style="border: 0px;">% Survived</th>
          </tr>
        </table>
      </th>
      
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let nrrbY of reportData">
      <td>{{nrrbY.YearEcmo}}</td>
      <td class="alt">{{nrrbY.AnnRuns}}</td>
      <td class="alt">{{nrrbY.cumulativeRuns}}</td>
      <td class="alt">{{nrrbY.q1}}</td>
      <td class="alt">{{nrrbY.median}}</td>
      <td class="alt">{{nrrbY.q3}}</td>
      <td class="alt">{{nrrbY.LongRunTime}}</td>
      <td class="alt">{{nrrbY.NoSurvived}}</td>
      <td class="alt">{{nrrbY.PercSurv}}%</td>
    </tr>
  </tbody>
</table>

